import React from 'react';
import TablePagination from '@material-ui/core/TablePagination';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { observer } from 'mobx-react';

export default observer(({ pagination }) => {
  return <Paper>
    <TablePagination
      display="flex"
      flexDirection="row"
      component={Box}
      rowsPerPageOptions={[50, 100, 200]}
      count={pagination.total}
      rowsPerPage={pagination.perPage}
      page={pagination.page - 1}
      onChangePage={(e, page) => pagination.setPage(page + 1)}
      onChangeRowsPerPage={e => pagination.setPerPage(e.target.value)}
    />
  </Paper>
})
