import React, { useState } from 'react';
import { observer } from 'mobx-react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Switch from '@material-ui/core/Switch';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import AdminSelect from '../../../common/AdminSelect';
import Selector from '../../../common/Selector';
import { TRADE_PARTY_TYPES, COUNTRIES, ACCOUNT_STATUSES_MAP } from '../../../../data/TradeParty';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { toJS } from 'mobx';

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    '& > *': {
      marginLeft: theme.spacing(1),
    },
  },
  tab: {
    display: 'inline-block',
    padding: '15px 10px',
    cursor: 'pointer',
    borderRadius: '3px 3px 0px 0px',
  },
  tabActive: {
    fontWeight: 500,
    color: theme.palette.primary.main,
    backgroundColor: '#FFFFFF',
  },
  inputWrapper: {

  },
}));

const tabsData = [
  {
    value: 0,
    text: 'General',
  },
  {
    value: 1,
    text: 'Account',
  },
];

const Form = observer(({ tradePartyId, values, submit, loading, submitting, tradeParties, addContact }) => {
  const classes = useStyles();
  const [isPublic, setIsPublic] = useState(get(values, 'admin_id', 0) == 0);
  const [tab, setTab] = useState(0);

  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    submit(e, tradePartyId, isPublic).then((done) => {
      if (done) {
        history.goBack();
        tradeParties.loadTradeParties();
      }
    });
  }

  const handleIsPublic = (e) => {
    setIsPublic(e.target.checked);
  }

  const handleContactChange = (i, e) => {
    const { name, value, type, checked } = e.target

    const contacts = [...toJS(values).contacts];
    if (type === 'checkbox') {
      contacts[i][name] = checked;
    } else {
      contacts[i][name] = value;
    }

    values.setAttributeByEvent({
      target: {
        name: 'contacts',
        value: contacts
      }
    })
  }

  const handleDeleteContact = (i) => {
    const contacts = [...toJS(values).contacts];
    contacts.splice(i, 1);
    values.setAttributeByEvent({
      target: {
        name: 'contacts',
        value: contacts
      }
    })
  }

  return <>
    <Box visibility={loading ? 'visible' : 'hidden'}><LinearProgress /></Box>
    <Box>
      {tabsData.map(data => <Box key={data.value} className={clsx(classes.tab, { [classes.tabActive]: tab === data.value })} onClick={() => setTab(data.value)}>
        <span>{data.text}</span>
      </Box>)}
    </Box>
    <form onSubmit={handleSubmit}>
      {tab === 0 && <><Box className={classes.inputWrapper} display="flex">
        <Box>
          <Box>
            <TextField
              label="NAME"
              name="name"
              margin="normal"
              value={values.name || ''}
              onChange={values.setAttributeByEvent}
              fullWidth
              required
            />
          </Box>
          <Box display="flex">
            <TextField
              label="CODE"
              name="code"
              margin="normal"
              value={values.code || ''}
              onChange={values.setAttributeByEvent}
              fullWidth
              required
            />
            <TextField
              label="TAX ID NUMBER"
              name="tax_id_number"
              margin="normal"
              value={values.tax_id_number || ''}
              onChange={values.setAttributeByEvent}
              fullWidth
            />
            <Box mt={2}>
              <FormControl fullWidth required>
                <InputLabel id="tradePartyTypeSelector">TYPE</InputLabel>
                <Selector labelId="tradePartyTypeSelector" menuItems={TRADE_PARTY_TYPES} name="type" value={values.type || ''} onChange={values.setAttributeByEvent} fullWidth required />
              </FormControl>
            </Box>
          </Box>
          <Box display="flex">
            <Box mt={2}>
              <FormControl fullWidth required>
                <InputLabel id="tradePartyCountrySelector">COUNTRY</InputLabel>
                <Selector labelId="tradePartyCountrySelector" menuItems={COUNTRIES} name="country" value={values.country || ''} onChange={values.setAttributeByEvent} fullWidth required />
              </FormControl>
            </Box>
            <TextField
              label="CITY"
              name="city"
              margin="normal"
              value={values.city || ''}
              onChange={values.setAttributeByEvent}
              fullWidth
              required
            />
            <TextField
              label="STATE"
              name="state"
              margin="normal"
              value={values.state || ''}
              onChange={values.setAttributeByEvent}
              fullWidth
              required
            />
            <TextField
              label="ZIPCODE"
              name="zipcode"
              margin="normal"
              value={values.zipcode || ''}
              onChange={values.setAttributeByEvent}
              fullWidth
              required
            />
          </Box>
          <Box>
            <TextField
              label="STREET ADDRESS"
              name="address"
              margin="normal"
              value={values.address || ''}
              onChange={values.setAttributeByEvent}
              multiline
              rows={5}
              fullWidth
              required
            />
          </Box>

          {!isPublic && <FormControl fullWidth required>
            <InputLabel id="adminSelect">ADMIN</InputLabel>
            <AdminSelect labelId="adminSelect" name="admin_id" value={values.admin_id || 0} onChange={values.setAttributeByEvent} fullWidth />
          </FormControl>}
        </Box>
        <Box ml={2}>
          <TextField
            label="OPEN HOURS"
            name="open_hours"
            margin="normal"
            value={values.open_hours || ''}
            onChange={values.setAttributeByEvent}
            fullWidth
          />
          <TextField
            label="BL REMARK"
            name="bl_remark"
            margin="normal"
            value={values.bl_remark || ''}
            onChange={values.setAttributeByEvent}
            fullWidth
          />
          <TextField
            label="BL Address (D/O Address)"
            name="bl_address"
            margin="normal"
            value={values.bl_address || ''}
            onChange={values.setAttributeByEvent}
            multiline
            rows={3}
            fullWidth
            required
          />
          <TextField
            label="Billing Address"
            name="billing_address"
            margin="normal"
            value={values.billing_address || ''}
            onChange={values.setAttributeByEvent}
            multiline
            rows={3}
            fullWidth
            required
          />
        </Box>
      </Box>
        <Box display="flex" justifyContent={'space-between'} alignItems={'center'}>
          <Box >
            <h3>Contact List</h3>
          </Box>
          <Box ml={10}>
            <Button onClick={addContact} color="primary" variant="contained" size="small">Add</Button>
          </Box>
        </Box>

        <Box mt={2}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell >Type</TableCell>
                  <TableCell >Emailable</TableCell>
                  <TableCell >Main Contact</TableCell>
                  <TableCell >Name*</TableCell>
                  <TableCell >Phone</TableCell>
                  <TableCell >Cell</TableCell>
                  <TableCell >Fax</TableCell>
                  <TableCell >Email</TableCell>
                  <TableCell >Remark</TableCell>
                  <TableCell >Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(values.contacts || []).map((row, i) => (
                  <TableRow
                    key={i}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell >{row.id}</TableCell>
                    <TableCell >
                      <FormControl variant="standard" >
                        <Select
                          value={row.type}
                          onChange={(e) => handleContactChange(i, e)}
                          name="type"
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value="0">APT</MenuItem>
                          <MenuItem value="1">SALES</MenuItem>
                          <MenuItem value="2">DISPATCH</MenuItem>
                        </Select>
                      </FormControl></TableCell>
                    <TableCell >
                      <Switch
                        color="primary"
                        name="emailable"
                        checked={row.emailable}
                        onChange={(e) => handleContactChange(i, e)}
                      />
                    </TableCell>
                    <TableCell >
                      <Switch
                        color="primary"
                        name="main_contact"
                        checked={row.main_contact}
                        onChange={(e) => handleContactChange(i, e)}
                      />
                    </TableCell>
                    <TableCell >
                      <TextField
                        name="name"
                        value={row.name}
                        required
                        size="small"
                        onChange={(e) => handleContactChange(i, e)}
                      />
                    </TableCell>
                    <TableCell >
                      <TextField
                        name="phone"
                        value={row.phone}
                        size="small"
                        onChange={(e) => handleContactChange(i, e)}
                      />
                    </TableCell>
                    <TableCell >
                      <TextField
                        name="cell"
                        value={row.cell}
                        size="small"
                        onChange={(e) => handleContactChange(i, e)}
                      />
                    </TableCell>
                    <TableCell >
                      <TextField
                        name="fax"
                        value={row.fax}
                        size="small"
                        onChange={(e) => handleContactChange(i, e)}
                      />
                    </TableCell>
                    <TableCell >
                      <TextField
                        name="email"
                        value={row.email}
                        size="small"
                        onChange={(e) => handleContactChange(i, e)}
                      /></TableCell>
                    <TableCell >
                      <TextField
                        multiline
                        name="remark"
                        value={row.remark}
                        size="small"
                        onChange={(e) => handleContactChange(i, e)}
                      /></TableCell>
                    <TableCell >
                      <Button onClick={() => handleDeleteContact(i)} color="error" variant="contained" size="small">Delete</Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

        </Box>
      </>
      }
      {tab === 1 && <Box className={classes.inputWrapper}>
        <TextField
          label="ACCOUNT EMAIL"
          name="account_email"
          margin="normal"
          value={values.account_email || ''}
          onChange={values.setAttributeByEvent}
          fullWidth
        />
        <FormControl fullWidth required>
          <InputLabel id="accountStatusSelector">ACCOUNT STATUS</InputLabel>
          <Select labelId="accountStatusSelector" name="account_status" value={values.account_status || 0} onChange={values.setAttributeByEvent} fullWidth required >
            {Object.keys(ACCOUNT_STATUSES_MAP).map((account_status) =>
              <MenuItem value={ACCOUNT_STATUSES_MAP[account_status]}>{account_status}</MenuItem>
            )}
          </Select>
        </FormControl>
        <TextField
          label="ACCOUNT PASSWORD"
          name="account_password"
          margin="normal"
          value={values.account_password || ''}
          onChange={values.setAttributeByEvent}
          fullWidth
        />
      </Box>}
      <Box mt={2} display="flex" justifyContent="space-between">
        {tab == 0 ?
          <Box>
            <FormControlLabel
              control={<Switch color="primary" name="public" checked={isPublic} onChange={handleIsPublic} />}
              label="Public"
            />
          </Box>
          : <Box></Box>}
        <Box flexDirection="row-reverse" className={classes.buttonGroup}>
          <Button
            variant="contained"
            disabled={submitting}
            onClick={history.goBack}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={submitting}
          >
            Save
          </Button>
        </Box>
      </Box>
    </form>
  </>
})

export default Form;

Form.propTypes = {
}
