import { types, getParent, flow } from 'mobx-state-tree';
import { TradePartyFilter, TradePartyRow } from './models';
import { resolvePaginationStore } from '../PaginationStore';

const TradePartysStore = types
  .model('TradePartysStore', {
    filter: types.optional(TradePartyFilter, {}),
    tradeParties: types.array(TradePartyRow),
    pagination: types.optional(
      resolvePaginationStore({
        onChangePerPage: (self) => getParent(self).loadTradeParties(),
        onChangePage: (self, page) => getParent(self).loadTradeParties({ page }),
      }),
      {
        perPage: 50
      },
    ),
  })
  .volatile(self => ({
    loading: false,
  }))
  .views(self => ({
    get root() {
      return getParent(self);
    },
  }))
  .actions(self => ({
    loadTradeParties: flow(function* ({ page = 1 } = {}) {
      try {
        self.loading = true;
        const response = yield self.root.api.tradeParty.index({ page: page, per_page: self.pagination.perPage, ...self.filter });
        self.tradeParties = response.data.data;
        self.pagination.setFromMeta(response.data.meta);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    destroy: flow(function* (id) {
      let response;
      if (window.confirm('Are you sure to delete it?')) {
        try {
          self.loading = true;
          response = yield self.root.api.tradeParty.destroy(id);
          self.loadTradeParties();
        } catch (error) {
          self.root.ui.toast.error(error);
        }
        self.loading = false;
      }
    }),
  }))


export default TradePartysStore;
