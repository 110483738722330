import { types, getParent } from 'mobx-state-tree';
import { hasValuesTrait } from '../helper';
import { AdminRow } from '../admin/models';

export const OceanCarrierRow = types
  .model({
    id: types.number,
    admin_id: 0,
    admin: types.maybeNull(AdminRow),
    code: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    billing: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    contact: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
  })
  .actions(self => ({
    destroy() {
      getParent(self, 2).destroy(self.id);
    },
    ...hasValuesTrait(self),
  }))

export const OceanCarrierDetail = types
  .model({
    id: types.maybeNull(types.number),
    admin_id: 0,
    admin: types.maybeNull(AdminRow),
    code: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    billing: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    contact: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
  })
  .actions(self => ({
    ...hasValuesTrait(self),
  }))
