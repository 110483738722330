import { types, getParent } from 'mobx-state-tree';
import { hasValuesTrait } from '../helper';
import { AdminRow } from '../admin/models';

export const TerminalRow = types
  .model({
    id: types.number,
    admin_id: 0,
    admin: types.maybeNull(AdminRow),
    name: types.maybeNull(types.string),
    firms: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    city: types.maybeNull(types.string),
    street: types.maybeNull(types.string),
    zipcode: types.maybeNull(types.string),
    delivery_order_address: types.maybeNull(types.string),
    memo: types.maybeNull(types.string),
  })
  .actions(self => ({
    destroy() {
      getParent(self, 2).destroy(self.id);
    },
    ...hasValuesTrait(self),
  }))

export const TerminalDetail = types
  .model({
    id: types.maybeNull(types.number),
    admin_id: 0,
    admin: types.maybeNull(AdminRow),
    name: types.maybeNull(types.string),
    firms: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    city: types.maybeNull(types.string),
    street: types.maybeNull(types.string),
    zipcode: types.maybeNull(types.string),
    delivery_order_address: types.maybeNull(types.string),
    memo: types.maybeNull(types.string),
  })
  .actions(self => ({
    ...hasValuesTrait(self),
  }))
