import { types, getParent } from 'mobx-state-tree';
import { hasValuesTrait } from '../helper';

export const AdminRow = types
  .model({
    id: types.number,
    name: types.maybeNull(types.string),
    username: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    company: types.maybeNull(types.string),
    company_address: types.maybeNull(types.string),
    contact: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    fax: types.maybeNull(types.string),
    do_instruction: types.maybeNull(types.string),
    billing_address: types.maybeNull(types.string),
    api_token: types.maybeNull(types.string),
    api_v2_enabled: types.maybeNull(types.boolean),
  })
  .actions(self => ({
    importEntitiesDefaultData() {
      getParent(self, 2).importEntitiesDefaultData(self.id);
    },
    delete() {
      getParent(self, 2).delete(self.id);
    },
    ...hasValuesTrait(self),
  }))

export const AdminDetail = types
  .model({
    id: types.maybeNull(types.number),
    name: types.maybeNull(types.string),
    username: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    password: types.maybeNull(types.string),
    company: types.maybeNull(types.string),
    company_address: types.maybeNull(types.string),
    contact: types.maybeNull(types.string),
    phone: types.maybeNull(types.string),
    fax: types.maybeNull(types.string),
    do_instruction: types.maybeNull(types.string),
    billing_address: types.maybeNull(types.string),
    api_token: types.maybeNull(types.string),
    api_v2_enabled: types.maybeNull(types.boolean),
  })
  .actions(self => ({
    ...hasValuesTrait(self),
  }))
