import React, { useEffect } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { subAdmin, toast } from '../../services';

export default ({ ...props }) => {
  const [data, setData] = React.useState([])

  useEffect(() => {
    subAdmin.all()
      .then(response => setData(response.data.data))
      .catch(error => toast.error(error))
  }, []);

  return (
    <>
      <Select {...props} >
        <MenuItem value={0}>&nbsp;</MenuItem>
        {data.map((admin) =>
          <MenuItem value={admin.id}>{admin.name}</MenuItem>
        )}
      </Select>
    </>
  )
}
