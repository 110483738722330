import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Form from './Form';

export default inject('root')(
  observer(({ root, locationId }) => {
    const { location, loadLocation, submit, loading, submitting } = root.location;
    
    locationId = locationId || useParams().locationId;

    useEffect(() => {
      if (locationId != 0) {
        loadLocation(locationId);
      }
    }, []);

    return <>
      <Form
        locationId={locationId}
        values={location}
        submit={submit}
        loading={loading}
        submitting={submitting}
        locations={root.locations}
      />
    </>
  })
);
