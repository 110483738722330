import React, { useState } from 'react';
import { observer } from 'mobx-react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import AdminSelect from '../../../common/AdminSelect';

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    '& > *': {
      marginLeft: theme.spacing(1),
    },
  },
}));

const Form = observer(({ oceanCarrierId, values, submit, loading, submitting, oceanCarriers }) => {
  const classes = useStyles();
  const [isPublic, setIsPublic] = useState(get(values, 'admin_id', 0) == 0);

  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    submit(e, oceanCarrierId, isPublic).then((done) => {
      if (done) {
        history.goBack();
        oceanCarriers.loadOceanCarriers();
      }
    });
  }

  const handleIsPublic = (e) => {
    setIsPublic(e.target.checked);
  }

  return <>
    <Box visibility={loading ? 'visible' : 'hidden'}><LinearProgress /></Box>
    <form onSubmit={handleSubmit}>
      <TextField
        label="CODE"
        name="code"
        margin="normal"
        value={values.code || ''}
        onChange={values.setAttributeByEvent}
        fullWidth
        required
      />
      <TextField
        label="NAME"
        name="name"
        margin="normal"
        value={values.name || ''}
        onChange={values.setAttributeByEvent}
        fullWidth
        required
      />
      <TextField
        label="BILLING"
        name="billing"
        margin="normal"
        value={values.billing || ''}
        onChange={values.setAttributeByEvent}
        fullWidth
      />
      <TextField
        label="PHONE"
        name="phone"
        margin="normal"
        value={values.phone || ''}
        onChange={values.setAttributeByEvent}
        fullWidth
      />
      <TextField
        label="CONTACT"
        name="contact"
        margin="normal"
        value={values.contact || ''}
        onChange={values.setAttributeByEvent}
        fullWidth
      />
      <TextField
        label="EMAIL"
        name="email"
        margin="normal"
        value={values.email || ''}
        onChange={values.setAttributeByEvent}
        fullWidth
      />
      {!isPublic && <FormControl fullWidth required>
        <InputLabel id="adminSelect">ADMIN</InputLabel>
        <AdminSelect labelId="adminSelect" name="admin_id" value={values.admin_id || 0} onChange={values.setAttributeByEvent} fullWidth />
      </FormControl>}
      <Box mt={2} display="flex" justifyContent="space-between">
        <Box>
          <FormControlLabel
            control={<Switch color="primary" name="public" checked={isPublic} onChange={handleIsPublic} />}
            label="Public"
          />
        </Box>
        <Box flexDirection="row-reverse" className={classes.buttonGroup}>
          <Button
            variant="contained"
            disabled={submitting}
            onClick={history.goBack}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={submitting}
          >
            Save
      </Button>
        </Box>
      </Box>
    </form>
  </>
})

export default Form;

Form.propTypes = {
}
