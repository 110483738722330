import { types, getParent } from 'mobx-state-tree';
import { hasValuesTrait } from '../helper';

export const SubAdminRow = types
  .model({
    id: types.number,
    username: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    admin_id: types.maybeNull(types.number),
    enabled: types.boolean,
    roles: types.array(types.string),
    permissions: types.array(types.string),
  })
  .actions(self => ({
    destroy() {
      getParent(self, 2).destroy(self.id);
    },
    ...hasValuesTrait(self),
  }))

export const SubAdminDetail = types
  .model({
    id: types.maybeNull(types.number),
    username: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    admin_id: types.maybeNull(types.number),
    enabled: true,
    password: types.maybeNull(types.string),
    roles: types.array(types.string),
    permissions: types.array(types.string),
  })
  .actions(self => ({
    ...hasValuesTrait(self),
    addRole(role) {
      self.roles.push(role);
      self.roles = [...new Set(self.roles)];
    },
    deleteRole(role) {
      self.roles = self.roles.filter((r) => r != role);
    },
  }))
