import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Header from './Header';
import Table from './Table';

export default inject('root')(
    observer(({ root }) => {
        const { centerAdmins, loadCenterAdmins, loading } = root.centerAdmins;

        useEffect(() => {
            loadCenterAdmins();
        }, []);

        return (
            <>
                <Header />
                <Table
                    loading={loading}
                    centerAdmins={centerAdmins}
                />
            </>
        );
    })
)