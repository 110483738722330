import React, { useEffect } from 'react';
import { PropTypes, observer } from 'mobx-react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router-dom';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Switch from '@material-ui/core/Switch';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import { SUB_ADMIN_ROLES } from '../../../../data';

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    '& > *': {
      marginLeft: theme.spacing(1),
    },
  },
}));

const Form = observer(({ subAdminId, values, submit, loading, submitting, subAdmins }) => {
  const classes = useStyles();

  const history = useHistory();

  const params = useParams();

  const handleSubmit = (e) => {
    e.preventDefault();
    submit(e, subAdminId).then((done) => {
      if (done) {
        history.goBack();
        subAdmins.loadSubAdmins(values.admin_id);
      }
    });
  }

  useEffect(() => {
    if (subAdminId) {
      values.setAttribute('admin_id', parseInt(params.adminId));
    }
  }, [params.adminId]);

  return <>
    <Box visibility={loading ? 'visible' : 'hidden'}><LinearProgress /></Box>
    <form onSubmit={handleSubmit}>
      <TextField
        label="NAME"
        name="name"
        margin="normal"
        value={values.name || ''}
        onChange={values.setAttributeByEvent}
        fullWidth
        required
      />
      <TextField
        label="USERNAME"
        name="username"
        margin="normal"
        value={values.username || ''}
        onChange={values.setAttributeByEvent}
        fullWidth
        required
      />
      <TextField
        label="EMAIL"
        name="email"
        type="email"
        margin="normal"
        value={values.email || ''}
        onChange={values.setAttributeByEvent}
        fullWidth
        required
      />

      <TextField
        label="PASSWORD"
        name="password"
        margin="normal"
        value={values.password || ''}
        onChange={values.setAttributeByEvent}
        fullWidth
        required={subAdminId == 0}
      />

      <FormControl required component="fieldset" margin="normal" >
        <FormLabel component="legend">Roles</FormLabel>
        <FormGroup>
          {SUB_ADMIN_ROLES.map(role => <FormControlLabel
            key={role}
            control={<Checkbox checked={values.roles.indexOf(role) !== -1} onChange={e => e.target.checked ? values.addRole(role) : values.deleteRole(role)} name={role} />}
            label={role}
          />)}
        </FormGroup>
      </FormControl>

      <Box mt={2} display="flex" flexDirection="row-reverse" className={classes.buttonGroup}>
        <Button
          variant="contained"
          disabled={submitting}
          onClick={history.goBack}
        >
          Cancel
      </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={submitting}
        >
          Save
      </Button>
        <FormControlLabel
          control={<Switch color="primary" checked={values.enabled} onChange={values.setAttributeByEvent} name="enabled" />}
          label="Enabled"
        />
      </Box>
    </form>
  </>
})

export default Form;

Form.propTypes = {
  form: PropTypes.objectOrObservableObject.isRequired,
}
