import React from 'react';
import { observer } from 'mobx-react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Link } from 'react-router-dom';

import { get } from 'lodash';

export default observer(({ terminal, location }) => {
  return <TableRow key={terminal.id}>
    <TableCell component="th" scope="row">{terminal.id}</TableCell>
    <TableCell>{terminal.name}</TableCell>
    <TableCell>{terminal.firms}</TableCell>
    <TableCell>{terminal.type}</TableCell>
    <TableCell>{terminal.city}</TableCell>
    <TableCell>{terminal.street}</TableCell>
    <TableCell>{terminal.zipcode}</TableCell>
    <TableCell>{terminal.delivery_order_address}</TableCell>
    <TableCell>{terminal.memo}</TableCell>
    <TableCell>{terminal.admin_id != 0 ? get(terminal, 'admin.name') :  <Chip color="primary" label="Public" />}</TableCell>
    <TableCell>
      <IconButton color="primary" size="small" disabled={terminal.loading} component={Link} to={{
        pathname: `/terminals/${terminal.id}`,
        state: { background: location }
      }}>
        <EditIcon fontSize="inherit" />
      </IconButton>
      <IconButton color="secondary" size="small" disabled={terminal.loading} onClick={terminal.destroy}>
        <DeleteIcon fontSize="inherit" />
      </IconButton>
    </TableCell>
  </TableRow>
})
