import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Form from './Form';

export default inject('root')(
  observer(({ root, adminId }) => {
    const { admin, loadAdmin, submit, loading, submitting, regenerateToken } = root.admin;
    adminId = adminId || useParams().adminId;

    useEffect(() => {
      if (adminId != 0) {
        loadAdmin(adminId);
      }
    }, []);

    return <>
      <Form
        adminId={adminId}
        values={admin}
        submit={submit}
        loading={loading}
        submitting={submitting}
        regenerateToken={regenerateToken}
      />
    </>
  })
);
