import React from 'react';
import { PropTypes, observer } from 'mobx-react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { FormControlLabel, Switch } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    '& > *': {
      marginLeft: theme.spacing(1),
    },
  },
}));

const Form = observer(({ adminId, values, submit, loading, submitting, regenerateToken }) => {
  const classes = useStyles();

  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    submit(e, adminId).then((done) => done && history.goBack());
  }

  const handleRegenerateToken = (e) => {
    e.preventDefault();
    regenerateToken(adminId);
  }

  return <>
    <Box visibility={loading ? 'visible' : 'hidden'}><LinearProgress /></Box>
    <form noValidate onSubmit={handleSubmit}>
      <TextField
        label="NAME"
        name="name"
        margin="normal"
        value={values.name || ''}
        onChange={values.setAttributeByEvent}
        fullWidth
        required
      />
      <TextField
        label="USERNAME"
        name="username"
        margin="normal"
        value={values.username || ''}
        onChange={values.setAttributeByEvent}
        fullWidth
        required
      />
      <TextField
        label="EMAIL"
        name="email"
        margin="normal"
        value={values.email || ''}
        onChange={values.setAttributeByEvent}
        fullWidth
        required
      />
      <TextField
        label="PASSWORD"
        name="password"
        margin="normal"
        value={values.password || ''}
        onChange={values.setAttributeByEvent}
        fullWidth
      />
      <TextField
        label="Company"
        name="company"
        margin="normal"
        value={values.company || ''}
        onChange={values.setAttributeByEvent}
        fullWidth
        required
      />
      <TextField
        label="Company Address"
        name="company_address"
        margin="normal"
        value={values.company_address || ''}
        onChange={values.setAttributeByEvent}
        fullWidth
        required
      />
      <TextField
        label="Contact"
        name="contact"
        margin="normal"
        value={values.contact || ''}
        onChange={values.setAttributeByEvent}
        fullWidth
        required
      />
      <TextField
        label="Phone"
        name="phone"
        margin="normal"
        value={values.phone || ''}
        onChange={values.setAttributeByEvent}
        fullWidth
        required
      />
      <TextField
        label="Fax"
        name="fax"
        margin="normal"
        value={values.fax || ''}
        onChange={values.setAttributeByEvent}
        fullWidth
      />
      <TextField
        label="Do Instruction"
        name="do_instruction"
        margin="normal"
        multiline
        rows={3}
        value={values.do_instruction || ''}
        onChange={values.setAttributeByEvent}
        fullWidth
      />
      <TextField
        label="Billing Address"
        name="billing_address"
        margin="normal"
        multiline
        rows={3}
        value={values.billing_address || ''}
        onChange={values.setAttributeByEvent}
        fullWidth
      />
      <TextField
        label="Drayeasy API Token"
        name="api_token"
        margin="normal"
        value={values.api_token || ''}
        onChange={values.setAttributeByEvent}
        fullWidth
      />
      <Button size="small" onClick={handleRegenerateToken}>Regenerate Token </Button>
      <FormControlLabel
        control={<Switch
          label="Enable API"
          name="api_v2_enabled"
          margin="normal"
          color="primary"
          checked={values.api_v2_enabled}
          onChange={values.setAttributeByEvent}
          fullWidth
        />}
        label="Enable API"
      />

      <Box mt={2} display="flex" flexDirection="row-reverse" className={classes.buttonGroup}>
        <Button
          variant="contained"
          disabled={submitting}
          onClick={history.goBack}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={submitting}
        >
          Save
        </Button>
      </Box>
    </form>
  </>
})

export default Form;

Form.propTypes = {
  form: PropTypes.objectOrObservableObject.isRequired,
}
