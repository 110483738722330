import request from './request';

export const admin = {
  index(params = {}) {
    return request.get('admins', { params });
  },
  show(id) {
    return request.get(`admins/${id}`);
  },
  store(values) {
    return request.post(`admins`, values)
  },
  update(id, values) {
    return request.put(`admins/${id}`, values)
  },
  destroy(id) {
    return request.delete(`admins/${id}`)
  },
  importEntitiesDefaultData(id) {
    return request.post(`admins/${id}/importEntitiesDefaultData`);
  },
  regenerateToken(id) {
    return request.post(`admins/${id}/regenerateToken`);
  }
}

