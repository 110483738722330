import { types, getParent } from 'mobx-state-tree';
import { hasValuesTrait } from '../helper';

export const ShippingModeRow = types
  .model({
    id: types.number,
    admin_id: 0,
    name: types.maybeNull(types.string),
    max_cbm: types.maybeNull(types.string),
    max_weight_lb: types.maybeNull(types.string),
  })
  .actions(self => ({
    destroy() {
      getParent(self, 2).destroy(self.id);
    },
    ...hasValuesTrait(self),
  }))

export const ShippingModeDetail = types
  .model({
    id: types.maybeNull(types.number),
    admin_id: 0,
    name: types.maybeNull(types.string),
    max_cbm: types.maybeNull(types.string),
    max_weight_lb: types.maybeNull(types.string),
  })
  .actions(self => ({
    ...hasValuesTrait(self),
  }))
