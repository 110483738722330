import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Form from './Form';

export default inject('root')(
  observer(({ root, generalLedgerCodeId }) => {
    const { generalLedgerCode, loadGeneralLedgerCode, submit, loading, submitting } = root.generalLedgerCode;
    
    generalLedgerCodeId = generalLedgerCodeId || useParams().generalLedgerCodeId;

    useEffect(() => {
      if (generalLedgerCodeId != 0) {
        loadGeneralLedgerCode(generalLedgerCodeId);
      }
    }, []);

    return <>
      <Form
        generalLedgerCodeId={generalLedgerCodeId}
        values={generalLedgerCode}
        submit={submit}
        loading={loading}
        submitting={submitting}
        generalLedgerCodes={root.generalLedgerCodes}
      />
    </>
  })
);
