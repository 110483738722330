import React, { useState } from 'react';
import { observer } from 'mobx-react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import AdminSelect from '../../../common/AdminSelect';
import Selector from '../../../common/Selector';
import { TERMINAL_TYPES } from '../../../../data/Terminal';

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    '& > *': {
      marginLeft: theme.spacing(1),
    },
  },
}));

const Form = observer(({ terminalId, values, submit, loading, submitting, terminals }) => {
  const classes = useStyles();
  const [isPublic, setIsPublic] = useState(get(values, 'admin_id', 0) == 0);

  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    submit(e, terminalId, isPublic).then((done) => {
      if (done) {
        history.goBack();
        terminals.loadTerminals();
      }
    });
  }

  const handleIsPublic = (e) => {
    setIsPublic(e.target.checked);
  }

  return <>
    <Box visibility={loading ? 'visible' : 'hidden'}><LinearProgress /></Box>
    <form onSubmit={handleSubmit}>
      <TextField
        label="NAME"
        name="name"
        margin="normal"
        value={values.name || ''}
        onChange={values.setAttributeByEvent}
        fullWidth
        required
      />
      <TextField
        label="FIRMS"
        name="firms"
        margin="normal"
        value={values.firms || ''}
        onChange={values.setAttributeByEvent}
        fullWidth
      />
      <FormControl fullWidth required>
        <InputLabel id="terminalTypeSelector">TYPE</InputLabel>
        <Selector labelId="terminalTypeSelector" menuItems={TERMINAL_TYPES} name="type" value={values.type || ''} onChange={values.setAttributeByEvent} fullWidth />
      </FormControl>
      <TextField
        label="CITY"
        name="city"
        margin="normal"
        value={values.city || ''}
        onChange={values.setAttributeByEvent}
        fullWidth
      />
      <TextField
        label="STREET"
        name="street"
        margin="normal"
        value={values.street || ''}
        onChange={values.setAttributeByEvent}
        fullWidth
      />
      <TextField
        label="ZIPCODE"
        name="zipcode"
        margin="normal"
        value={values.zipcode || ''}
        onChange={values.setAttributeByEvent}
        fullWidth
      />
      <TextField
        label="D/O ADDRESS"
        name="delivery_order_address"
        margin="normal"
        multiline
        rows={3}
        value={values.delivery_order_address || ''}
        onChange={values.setAttributeByEvent}
        fullWidth
      />
      <TextField
        label="MEMO"
        name="memo"
        margin="normal"
        value={values.memo || ''}
        onChange={values.setAttributeByEvent}
        fullWidth
      />
      {!isPublic && <FormControl fullWidth required>
        <InputLabel id="adminSelect">ADMIN</InputLabel>
        <AdminSelect labelId="adminSelect" name="admin_id" value={values.admin_id || 0} onChange={values.setAttributeByEvent} fullWidth />
      </FormControl>}
      <Box mt={2} display="flex" justifyContent="space-between">
        <Box>
          <FormControlLabel
            control={<Switch color="primary" name="public" checked={isPublic} onChange={handleIsPublic} />}
            label="Public"
          />
        </Box>
        <Box flexDirection="row-reverse" className={classes.buttonGroup}>
          <Button
            variant="contained"
            disabled={submitting}
            onClick={history.goBack}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={submitting}
          >
            Save
          </Button>
        </Box>
      </Box>
    </form>
  </>
})

export default Form;

Form.propTypes = {
}
