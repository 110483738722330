import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Form from './Form';

export default inject('root')(
  observer(({ root, tradePartyId }) => {
    const { tradeParty, loadTradeParty, submit, loading, submitting, addContact } = root.tradeParty;
    tradePartyId = tradePartyId || useParams().tradePartyId;

    useEffect(() => {
      if (tradePartyId != 0) {
        loadTradeParty(tradePartyId);
      }
    }, []);

    return <>
      <Form
        tradePartyId={tradePartyId}
        values={tradeParty}
        submit={submit}
        loading={loading}
        submitting={submitting}
        tradeParties={root.tradeParties}
        addContact={addContact}
      />
    </>
  })
);
