import { types, getParent, applySnapshot, flow } from "mobx-state-tree";

const SettingStore = types
  .model("SettingStore", {
    phpinfo: "",
  })
  .volatile((self) => ({
    loading: false,
    submitting: false,
  }))
  .views((self) => ({
    get root() {
      return getParent(self);
    },
  }))
  .actions((self) => ({
    loadPhpinfo: flow(function* () {
      try {
        self.loading = true;
        self.phpinfo = "";
        const response = yield self.root.api.setting.phpinfo();
        self.phpinfo = response.data.data;
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
  }));

export default SettingStore;
