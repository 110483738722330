import { types, getParent } from 'mobx-state-tree';
import { hasValuesTrait } from '../helper';

export const LocationRow = types
  .model({
    id: types.number,
    code: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    country: types.maybeNull(types.string),
    subdiv: types.maybeNull(types.string),
    coordinates: types.maybeNull(types.string),
  })
  .actions(self => ({
    destroy() {
      getParent(self, 2).destroy(self.id);
    },
    ...hasValuesTrait(self),
  }))

export const LocationDetail = types
  .model({
    id: types.maybeNull(types.number),
    code: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    country: types.maybeNull(types.string),
    subdiv: types.maybeNull(types.string),
    coordinates: types.maybeNull(types.string),
  })
  .actions(self => ({
    ...hasValuesTrait(self),
  }))
