import request from './request';

export const shippingMode = {
  index(params = {}) {
    return request.get(`shippingModes`, { params });
  },
  show(id) {
    return request.get(`shippingModes/${id}`);
  },
  store(values) {
    return request.post(`shippingModes`, values);
  },
  update(id, values) {
    return request.put(`shippingModes/${id}`, values);
  },
  destroy(id) {
    return request.delete(`shippingModes/${id}`);
  },
}
