import { types, getParent, flow } from 'mobx-state-tree';
import { SubAdminRow } from './models';

const SubAdminsStore = types
  .model('SubAdminsStore', {
    subAdmins: types.array(SubAdminRow),
  })
  .volatile(self => ({
    loading: false,
  }))
  .views(self => ({
    get root() {
      return getParent(self);
    },
  }))
  .actions(self => ({
    loadSubAdmins: flow(function* (adminId) {
      try {
        self.loading = true;
        const response = yield self.root.api.subAdmin.index(adminId);
        self.subAdmins = response.data.data;
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    destroy: flow(function* (id) {
      let response;
      if (window.confirm('Are you sure to delete it?')) {
        try {
          self.loading = true;
          response = yield self.root.api.subAdmin.destroy(id);
        } catch (error) {
          self.root.ui.toast.error(error);
        }
        self.loading = false;
      }
    }),
  }))


export default SubAdminsStore;
