import request from './request';

export const generalLedgerCode = {
  index(params = {}) {
    return request.get(`generalLedgerCodes`, { params });
  },
  show(id) {
    return request.get(`generalLedgerCodes/${id}`);
  },
  store(values) {
    return request.post(`generalLedgerCodes`, values);
  },
  update(id, values) {
    return request.put(`generalLedgerCodes/${id}`, values);
  },
  destroy(id) {
    return request.delete(`generalLedgerCodes/${id}`);
  },
}
