import React from 'react';
import { inject } from 'mobx-react';
import { useLocation, Redirect } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import InputBase from '@material-ui/core/InputBase';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Logo from '../ui/Logo';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(20),
    display: 'flex',
    flexDirection: 'column',
  },
  logo: {
    width: '140px',
    height: '86.32px',
  },
  title: {
    margin: '6.68px 0px 0px 0px',
    fontFamily: 'Roboto',
    fontSize: '36px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '150%',
    letterSpacing: '0.1em',
    color: '#000000',
  },
  welcome: {
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '150%',
    letterSpacing: '0.085em',
  },
  inputLabel: {
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '150%',
  },
  input: {
    height: '40px',
    border: '1px solid #80889A',
    margin: '5px 0px',
    padding: '0px 5px',
    boxSizing: 'border-box',
    borderRadius: '3px',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: '47px',
  },
  check: {
    margin: '17px 0px 0px',
  },
  checkboxRoot: {
    padding: '0px',
  },
  checkboxLabel: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '150%',
  },
  submit: {
    marginTop: '97px',
    borderRadius: '3px',
  },
}));


export default inject(stores => ({
  username: stores.root.auth.loginForm.username,
  password: stores.root.auth.loginForm.password,
  setValueByElement: stores.root.auth.loginForm.setValueByElement,
  submitting: stores.root.auth.loginForm.submitting,
  submit: stores.root.auth.loginForm.submit,
  isAuthenticated: stores.root.auth.isAuthenticated,
}))(({ username, password, setValueByElement, submitting, submit, isAuthenticated }) => {
  const classes = useStyles();
  const location = useLocation();

  const { from } = location.state || { from: { pathname: '/' } }

  if (isAuthenticated) {
    return <Redirect to={from} />
  }

  return (
    <Container component="main" maxWidth="xs" >
      <CssBaseline />
      <div className={classes.root}>
        {/* <Logo className={classes.logo} /> */}
        <div className={classes.title}>Sign in</div>
        <div className={classes.welcome}>Welcome to EasyFreight Center</div>
        <form className={classes.form} noValidate onSubmit={submit}>
          <div className={classes.inputLabel}>Username</div>
          <InputBase classes={{ input: classes.input }}
            required
            fullWidth
            type="text"
            id="username"
            name="username"
            autoComplete="username"
            value={username || ''}
            onChange={setValueByElement}
            autoFocus
          />
          <div className={classes.inputLabel}>Password</div>
          <InputBase
            classes={{ input: classes.input }}
            required
            fullWidth
            name="password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password || ''}
            onChange={setValueByElement}
          />

          <FormControlLabel classes={{root: classes.check, label: classes.checkboxLabel}}
            control={<Checkbox color="primary" classes={{ root: classes.checkboxRoot }} />}
            label="Keep me signed in"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={submitting}
          >
            Sign In
          </Button>
        </form>
      </div>
    </Container>
  )
});
