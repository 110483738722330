import { types, getParent } from 'mobx-state-tree';
import { hasValuesTrait } from '../helper';
import { GeneralLedgerCodeRow } from '../generalLedgerCode/models';

export const BillingCodeRow = types
  .model({
    id: types.number,
    admin_id: 0,
    revenue_general_ledger_code: types.maybeNull(GeneralLedgerCodeRow),
    cost_general_ledger_code: types.maybeNull(GeneralLedgerCodeRow),
    code: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    department: types.maybeNull(types.string),
    revenue_general_ledger_code_id: types.maybeNull(types.number),
    cost_general_ledger_code_id: types.maybeNull(types.number),
  })
  .actions(self => ({
    destroy() {
      getParent(self, 2).destroy(self.id);
    },
    ...hasValuesTrait(self),
  }))

export const BillingCodeDetail = types
  .model({
    id: types.maybeNull(types.number),
    admin_id: 0,
    code: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    department: types.maybeNull(types.string),
    revenue_general_ledger_code_id: types.maybeNull(types.number),
    cost_general_ledger_code_id: types.maybeNull(types.number),
  })
  .actions(self => ({
    ...hasValuesTrait(self),
  }))
