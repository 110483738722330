import request from './request';

export const subAdmin = {
  index(adminId, params = {}) {
    return request.get(`subAdmins/byAdmins/${adminId}`, params);
  },
  show(id) {
    return request.get(`subAdmins/${id}`);
  },
  store(values) {
    return request.post(`subAdmins`, values)
  },
  update(id, values) {
    return request.put(`subAdmins/${id}`, values)
  },
  destroy(id) {
    return request.delete(`subAdmins/${id}`)
  },
  all(params = {}) {
    return request.get(`subAdmins`, params);
  },
}
