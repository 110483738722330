import { types, getParent, applySnapshot, flow } from 'mobx-state-tree';
import { OceanCarrierDetail } from './models';

const OceanCarrierStore = types
  .model('OceanCarrierStore', {
    oceanCarrier: types.optional(OceanCarrierDetail, {}),
  })
  .volatile(self => ({
    loading: false,
    submitting: false,
  }))
  .views(self => ({
    get root() {
      return getParent(self);
    },
  }))
  .actions(self => ({
    setOceanCarrier(oceanCarrier) {
      applySnapshot(self.oceanCarrier, oceanCarrier);
    },
    loadOceanCarrier: flow(function* (id) {
      try {
        self.loading = true;
        self.setOceanCarrier({});
        const response = yield self.root.api.oceanCarrier.show(id);
        self.setOceanCarrier(response.data.data);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    submit: flow(function* (e, oceanCarrierId, isPublic) {
      e && e.preventDefault && e.preventDefault();

      let response;
      try {
        self.submitting = true;
        if (oceanCarrierId != 0) {         
          self.oceanCarrier.isPublic = isPublic;
          response = yield self.root.api.oceanCarrier.update(self.oceanCarrier.id, self.oceanCarrier);
        }
        else {
          response = yield self.root.api.oceanCarrier.store(self.oceanCarrier);
        }
        self.setOceanCarrier(self.oceanCarrier);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.submitting = false;

      return response;
    }),
  }))


export default OceanCarrierStore;
