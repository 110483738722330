import React from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

export default () => {
  return <TableHead>
    <TableRow>
      <TableCell>id</TableCell>
      <TableCell>name</TableCell>
      <TableCell>username</TableCell>
      <TableCell>email</TableCell>
      <TableCell></TableCell>
    </TableRow>
  </TableHead>
}
