import React from 'react';
import { observer } from 'mobx-react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import { Link, Link as RouteLink } from 'react-router-dom';

export default observer(({ centerAdmin, location }) => {
  return <TableRow key={centerAdmin.id}>
    <TableCell component="th" scope="row">{centerAdmin.id}</TableCell>
    <TableCell>{centerAdmin.name}</TableCell>
    <TableCell>{centerAdmin.username}</TableCell>
    <TableCell>{centerAdmin.email}</TableCell>
    <TableCell>
      <IconButton color="primary" size="small" disabled={centerAdmin.loading} component={RouteLink} to={{
        pathname: `/centerAdmins/${centerAdmin.id}`,
        state: { background: location }
      }}>
        <EditIcon fontSize="inherit" />
      </IconButton>
      <IconButton color="secondary" size="small" disabled={centerAdmin.loading} onClick={centerAdmin.destroy}>
        <DeleteIcon fontSize="inherit" />
      </IconButton>
    </TableCell>
  </TableRow>
})
