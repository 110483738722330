import request from './request';

export const auth = {
  user() {
    return request.get('user');
  },
  login(username, password) {
    return request.post('login', { username, password })
  },
  logout() {
    return request.delete('logout');
  }
}
