import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { Link, useLocation } from 'react-router-dom';

export default ({ }) => {
  const location = useLocation();

  return <>
    <Box display="flex">
      <Box>
        <Typography variant="h5" gutterBottom>BillingCodes</Typography>
      </Box>
      <Box ml={1}>
        <Button component={Link} size="small" variant="contained" color="primary" to={{
          pathname: `/billingCodes/0`,
          state: { background: location }
        }}>New</Button>
      </Box>
    </Box>
  </>
}
