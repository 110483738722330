import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Header from './Header';
import Table from './Table';

export default inject('root')(
  observer(({ root, adminId }) => {
    const { subAdmins, loadSubAdmins, loading } = root.subAdmins;
    adminId = adminId || useParams().adminId;

    useEffect(() => {
      loadSubAdmins(adminId);
    }, []);

    return (
      <>
        <Header adminId={adminId} />
        <Table
          adminId={adminId}
          loading={loading}
          subAdmins={subAdmins}
        />
      </>
    );
  })
)
