import React from 'react';
import { observer } from 'mobx-react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    '& > *': {
      marginLeft: theme.spacing(1),
    },
  },
}));

const Form = observer(({ locationId, values, submit, loading, submitting, locations }) => {
  const classes = useStyles();

  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    submit(e, locationId).then((done) => {
      if (done) {
        history.goBack();
        locations.loadLocations();
      }
    });
  }

  return <>
    <Box visibility={loading ? 'visible' : 'hidden'}><LinearProgress /></Box>
    <form onSubmit={handleSubmit}>
      <TextField
        label="CODE"
        name="code"
        margin="normal"
        value={values.code || ''}
        onChange={values.setAttributeByEvent}
        fullWidth
        required
      />
      <TextField
        label="NAME"
        name="name"
        margin="normal"
        value={values.name || ''}
        onChange={values.setAttributeByEvent}
        fullWidth
        required
      />
      <TextField
        label="COUNTRY"
        name="country"
        margin="normal"
        value={values.country || ''}
        onChange={values.setAttributeByEvent}
        fullWidth
        required
      />
      <TextField
        label="SUBDIV"
        name="subdiv"
        margin="normal"
        value={values.subdiv || ''}
        onChange={values.setAttributeByEvent}
        fullWidth
        required
      />
      <TextField
        label="COORDINATES"
        name="coordinates"
        margin="normal"
        value={values.coordinates || ''}
        onChange={values.setAttributeByEvent}
        fullWidth
        required
      />
      <Box mt={2} display="flex" flexDirection="row-reverse" className={classes.buttonGroup}>
        <Button
          variant="contained"
          disabled={submitting}
          onClick={history.goBack}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={submitting}
        >
          Save
      </Button>
      </Box>
    </form>
  </>
})

export default Form;

Form.propTypes = {
}
