import React from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

export default () => {
  return <TableHead>
    <TableRow>
      <TableCell>ID</TableCell>
      <TableCell>NAME</TableCell>
      <TableCell>FIRMS</TableCell>
      <TableCell>TYPE</TableCell>
      <TableCell>CITY</TableCell>
      <TableCell>STREET</TableCell>
      <TableCell>ZIPCODE</TableCell>
      <TableCell>D/O ADDRESS</TableCell>
      <TableCell>MEMO</TableCell>
      <TableCell>ADMIN</TableCell>
      <TableCell></TableCell>
    </TableRow>
  </TableHead>
}
