import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

export default inject('root')(
  observer(({ root, ...props }) => {
    const { admins, loadAdmins, } = root.admins;

    useEffect(() => {
      loadAdmins();
    }, []);

    return (
      <>
        <Select {...props} >
          <MenuItem value={0}>&nbsp;</MenuItem>
          {admins.map((admin) =>
            <MenuItem value={admin.id}>{admin.name}</MenuItem>
          )}
        </Select>
      </>
    );
  })
)