import { types, getParent } from 'mobx-state-tree';

const UIStore = types
  .model('UIStore', {
    loading: false,
  })
  .views(self => ({
    get root() {
      return getParent(self);
    },
    get toast() {
      return self.root.api.toast;
    }
  }))
  .actions(self => ({
    setLoading(loading) {
      self.loading = loading;
    },
  }));

export default UIStore;
