import React, { useState } from 'react';
import { observer } from 'mobx-react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Selector from '../../../common/Selector';
import GeneralLedgerCodeSelect from '../../../common/GeneralLedgerCodeSelect';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { BILLING_CODE_DEPARTMENTS } from '../../../../data';

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    '& > *': {
      marginLeft: theme.spacing(1),
    },
  },
}));

const Form = observer(({ billingCodeId, values, submit, loading, submitting, billingCodes }) => {
  const classes = useStyles();

  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    submit(e, billingCodeId).then((done) => {
      if (done) {
        history.goBack();
        billingCodes.loadBillingCodes();
      }
    });
  }

  return <>
    <Box visibility={loading ? 'visible' : 'hidden'}><LinearProgress /></Box>
    <form onSubmit={handleSubmit}>
      <TextField
        label="CODE"
        name="code"
        margin="normal"
        value={values.code || ''}
        onChange={values.setAttributeByEvent}
        fullWidth
        required
      />
      <TextField
        label="NAME"
        name="name"
        margin="normal"
        value={values.name || ''}
        onChange={values.setAttributeByEvent}
        fullWidth
        required
      />
      <FormControl fullWidth required>
        <InputLabel id="departmentSelector">DEPARTMENT</InputLabel>
        <Selector labelId="departmentSelector" menuItems={BILLING_CODE_DEPARTMENTS} name="department" value={values.department || ''} onChange={values.setAttributeByEvent} fullWidth />
      </FormControl>
      <FormControl fullWidth required>
        <InputLabel id="revenueGeneralLedgerCodeSelect">REVENUE</InputLabel>
        <GeneralLedgerCodeSelect labelId="revenueGeneralLedgerCodeSelect" name="revenue_general_ledger_code_id" value={values.revenue_general_ledger_code_id || 0} onChange={values.setAttributeByEvent} fullWidth />
      </FormControl>
      <FormControl fullWidth required>
        <InputLabel id="costGeneralLedgerCodeSelect">COST</InputLabel>
        <GeneralLedgerCodeSelect labelId="costGeneralLedgerCodeSelect" name="cost_general_ledger_code_id" value={values.cost_general_ledger_code_id || 0} onChange={values.setAttributeByEvent} fullWidth />
      </FormControl>
      <Box mt={2} display="flex" flexDirection="row-reverse" className={classes.buttonGroup}>
        <Button
          variant="contained"
          disabled={submitting}
          onClick={history.goBack}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={submitting}
        >
          Save
      </Button>
      </Box>
    </form>
  </>
})

export default Form;

Form.propTypes = {
}
