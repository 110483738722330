import React from 'react';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { Button } from '@material-ui/core';

const InnerSnackbarUtilsConfigurator = (props) => {
  props.setUseSnackbarRef(useSnackbar())
  return null
}

let useSnackbarRef
const setUseSnackbarRef = (useSnackbarRefProp) => {
  useSnackbarRef = useSnackbarRefProp
}

const SnackbarUtilsConfigurator = () => {
  return <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />
}

const notistackRef = React.createRef();
const onClickDismiss = key => () => {
  notistackRef.current.closeSnackbar(key);
}

const ToastProvider = ({ children }) => {
  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      autoHideDuration={3000}
      ref={notistackRef}
      action={(key) => (
        <Button onClick={onClickDismiss(key)}>
          Dismiss
        </Button>
      )}
    >
      <SnackbarUtilsConfigurator />
      {children}
    </SnackbarProvider>
  )
}

export {
  ToastProvider,
  useSnackbarRef,
}
