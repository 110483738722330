import request from './request';

export const billingCode = {
  index(params = {}) {
    return request.get(`billingCodes`, { params });
  },
  show(id) {
    return request.get(`billingCodes/${id}`);
  },
  store(values) {
    return request.post(`billingCodes`, values);
  },
  update(id, values) {
    return request.put(`billingCodes/${id}`, values);
  },
  destroy(id) {
    return request.delete(`billingCodes/${id}`);
  },
}
