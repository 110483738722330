import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Form from './Form';

export default inject('root')(
  observer(({ root, terminalId }) => {
    const { terminal, loadTerminal, submit, loading, submitting } = root.terminal;
    terminalId = terminalId || useParams().terminalId;

    useEffect(() => {
      if (terminalId != 0) {
        loadTerminal(terminalId);
      }
    }, []);

    return <>
      <Form
        terminalId={terminalId}
        values={terminal}
        submit={submit}
        loading={loading}
        submitting={submitting}
        terminals={root.terminals}
      />
    </>
  })
);
