import React from 'react';
import { observer } from 'mobx-react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Link } from 'react-router-dom';
import { get } from 'lodash';

export default observer(({ billingCode, location }) => {
  return <TableRow key={billingCode.id}>
    <TableCell component="th" scope="row">{billingCode.id}</TableCell>
    <TableCell>{billingCode.code}</TableCell>
    <TableCell>{billingCode.name}</TableCell>
    <TableCell>{billingCode.department}</TableCell>
    <TableCell>{get(billingCode, 'revenue_general_ledger_code.full_name')}</TableCell>
    <TableCell>{get(billingCode, 'cost_general_ledger_code.full_name')}</TableCell>
    <TableCell>
      <IconButton color="primary" size="small" disabled={billingCode.loading} component={Link} to={{
        pathname: `/billingCodes/${billingCode.id}`,
        state: { background: location }
      }}>
        <EditIcon fontSize="inherit" />
      </IconButton>
      <IconButton color="secondary" size="small" disabled={billingCode.loading} onClick={billingCode.destroy}>
        <DeleteIcon fontSize="inherit" />
      </IconButton>
    </TableCell>
  </TableRow>
})
