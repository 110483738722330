import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Header from './Header';
import Table from './Table';
import Pagination from './Pagination';
import { Box, Button, TextField } from '@material-ui/core';
import { toJS } from 'mobx';

export default inject('root')(
  observer(({ root }) => {
    const { tradeParties, loadTradeParties, loading, pagination, filter } = root.tradeParties;
    useEffect(() => {
      loadTradeParties();
    }, []);

    return (
      <>
        <Header />
        <Box display="flex" mb={2}>
          <Box >
            <TextField placeholder='Search code or name' name="code" value={filter.key || ''} onChange={(e) => filter.handleChange(e.target.value)} />
          </Box>
          <Box ml={2}>
            <Button size="small" color="primary" variant="contained" value={filter.key} onClick={() => loadTradeParties()} >Search</Button>
          </Box>
        </Box>
        <Table
          loading={loading}
          tradeParties={tradeParties}
        />
        <Pagination pagination={pagination} />
      </>
    );
  })
)
