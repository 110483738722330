import React, { useState } from 'react';
import { observer } from 'mobx-react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    '& > *': {
      marginLeft: theme.spacing(1),
    },
  },
}));

const Form = observer(({ shippingModeId, values, submit, loading, submitting, shippingModes }) => {
  const classes = useStyles();

  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    submit(e, shippingModeId).then((done) => {
      if (done) {
        history.goBack();
        shippingModes.loadShippingModes();
      }
    });
  }

  return <>
    <Box visibility={loading ? 'visible' : 'hidden'}><LinearProgress /></Box>
    <form onSubmit={handleSubmit}>
      <TextField
        label="NAME"
        name="name"
        margin="normal"
        value={values.name || ''}
        onChange={values.setAttributeByEvent}
        fullWidth
        required
      />
      <TextField
        label="MAX CBM"
        name="max_cbm"
        margin="normal"
        value={values.max_cbm || ''}
        onChange={values.setAttributeByEvent}
        fullWidth
        required
      />
      <TextField
        label="MAX WEIGHT LB"
        name="max_weight_lb"
        margin="normal"
        value={values.max_weight_lb || ''}
        onChange={values.setAttributeByEvent}
        fullWidth
        required
      />
      <Box mt={2} display="flex" flexDirection="row-reverse" className={classes.buttonGroup}>
        <Button
          variant="contained"
          disabled={submitting}
          onClick={history.goBack}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={submitting}
        >
          Save
      </Button>
      </Box>
    </form>
  </>
})

export default Form;

Form.propTypes = {
}
