import request from './request';

export const tradeParty = {
  index(params = {}) {
    return request.get(`tradeParties`, { params });
  },
  show(id) {
    return request.get(`tradeParties/${id}`);
  },
  store(values) {
    return request.post(`tradeParties`, values)
  },
  update(id, values) {
    return request.put(`tradeParties/${id}`, values)
  },
  destroy(id) {
    return request.delete(`tradeParties/${id}`)
  },
}
