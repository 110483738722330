import { types, getParent } from 'mobx-state-tree';
import { hasValuesTrait } from '../helper';
import { AdminRow } from '../admin/models';

export const TradePartyFilter = types.model({
  key: types.maybeNull(types.string),
}).actions(self => ({
  handleChange(value) {
    self.key = value;
  }
}))

const Contact = types.model({
  cell: types.maybeNull(types.string),
  centerdb_id: types.maybeNull(types.number),
  contactable_id: types.maybeNull(types.number),
  contactable_type: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  emailable: types.boolean,
  fax: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  main_contact: types.maybeNull(types.boolean),
  name: types.maybeNull(types.string),
  phone: types.maybeNull(types.string),
  remark: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
})

export const TradePartyRow = types
  .model({
    id: types.number,
    admin_id: 0,
    admin: types.maybeNull(AdminRow),
    code: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    country: types.maybeNull(types.string),
  })
  .actions(self => ({
    destroy() {
      getParent(self, 2).destroy(self.id);
    },
    ...hasValuesTrait(self),
  }))

export const TradePartyDetail = types
  .model({
    id: types.maybeNull(types.number),
    admin_id: 0,
    admin: types.maybeNull(AdminRow),
    code: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    tax_id_number: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    country: types.maybeNull(types.string),
    city: types.maybeNull(types.string),
    state: types.maybeNull(types.string),
    zipcode: types.maybeNull(types.string),
    address: types.maybeNull(types.string),
    open_hours: types.maybeNull(types.string),
    bl_remark: types.maybeNull(types.string),
    bl_address: types.maybeNull(types.string),
    billing_address: types.maybeNull(types.string),
    account_email: types.maybeNull(types.string),
    account_status: types.optional(types.maybeNull(types.number), 0),
    account_password: types.maybeNull(types.string),
    contacts: types.array(Contact),
  })
  .actions(self => ({
    ...hasValuesTrait(self),
  }))
