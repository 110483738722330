import React from 'react';
import { observer } from 'mobx-react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Link, Link as RouteLink } from 'react-router-dom';
import Chip from '@material-ui/core/Chip';


export default observer(({ adminId, subAdmin, location }) => {
  return <TableRow key={subAdmin.id}>
    <TableCell component="th" scope="row">{subAdmin.id}</TableCell>
    <TableCell>{subAdmin.name}</TableCell>
    <TableCell>{subAdmin.username}</TableCell>
    <TableCell>{subAdmin.email}</TableCell>
    <TableCell>{subAdmin.enabled ? <Chip label="YES" color="primary" /> : <Chip label="NO" disabled />}</TableCell>
    <TableCell>{subAdmin.roles.join(' ')}</TableCell>
    <TableCell>
      <IconButton color="primary" size="small" disabled={subAdmin.loading} component={RouteLink} to={{
        pathname: `/subAdmins/${subAdmin.id}`,
        state: { background: location }
      }}>
        <EditIcon fontSize="inherit" />
      </IconButton>
      <IconButton color="secondary" size="small" disabled={subAdmin.loading} onClick={subAdmin.destroy}>
        <DeleteIcon fontSize="inherit" />
      </IconButton>
    </TableCell>
  </TableRow>
})
