import React from 'react';
import { observer } from 'mobx-react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Link } from 'react-router-dom';

export default observer(({ location, backgroundLocation }) => {
  return <TableRow key={location.id}>
    <TableCell component="th" scope="row">{location.id}</TableCell>
    <TableCell>{location.code}</TableCell>
    <TableCell>{location.name}</TableCell>
    <TableCell>{location.country}</TableCell>
    <TableCell>{location.subdiv}</TableCell>
    <TableCell>{location.coordinates}</TableCell>
    <TableCell>
      <IconButton color="primary" size="small" disabled={location.loading} component={Link} to={{
        pathname: `/locations/${location.id}`,
        state: { background: backgroundLocation }
      }}>
        <EditIcon fontSize="inherit" />
      </IconButton>
      <IconButton color="secondary" size="small" disabled={location.loading} onClick={location.destroy}>
        <DeleteIcon fontSize="inherit" />
      </IconButton>
    </TableCell>
  </TableRow>
})
