import { types, getParent, applySnapshot, flow } from 'mobx-state-tree';
import { SubAdminDetail } from './models';

const SubAdminStore = types
  .model('SubAdminStore', {
    subAdmin: types.optional(SubAdminDetail, {}),
  })
  .volatile(self => ({
    loading: false,
    submitting: false,
  }))
  .views(self => ({
    get root() {
      return getParent(self);
    },
  }))
  .actions(self => ({
    setSubAdmin(subAdmin) {
      applySnapshot(self.subAdmin, subAdmin);
    },
    loadSubAdmin: flow(function* (id) {
      try {
        self.loading = true;
        self.setSubAdmin({});
        const response = yield self.root.api.subAdmin.show(id);
        self.setSubAdmin(response.data.data);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    submit: flow(function* (e, subAdminId) {
      e && e.preventDefault && e.preventDefault();
      
      let response;
      try {
        self.submitting = true;
        if (subAdminId != 0) {
          response = yield self.root.api.subAdmin.update(self.subAdmin.id, self.subAdmin);
        }
        else {
          response = yield self.root.api.subAdmin.store(self.subAdmin);
        }
        self.setSubAdmin(self.subAdmin);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.submitting = false;

      return response;
    }),
  }))


export default SubAdminStore;
