import React from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import {
  XYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  VerticalGridLines,
  VerticalBarSeries,
} from 'react-vis';
import { analysis } from '../../services/analysis';
import { toast } from '../../services';
import TextField from '@material-ui/core/TextField';
import SubAdminSelect from '../common/SubAdminSelect';
import LinearProgress from '@material-ui/core/LinearProgress';
import moment from 'moment';
import { MenuItem, Select } from '@material-ui/core';


const Home = () => {
  const [data, setData] = React.useState([])
  const [filter, setFilter] = React.useState({
    start_date: moment().subtract('7', 'd').format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
  })
  const [loading, setLoading] = React.useState(false)
  const [dateRange, setDateRange] = React.useState(7)

  const fetchData = () => {
    setLoading(true)
    analysis.pageview(filter)
      .then(response => setData(response.data.data.map(row => ({ x: row.page, y: row.count }))))
      .catch(error => toast.error(error))
      .then(() => setLoading(false))
  }

  const handleSubmitFilter = (e) => {
    e.preventDefault()
    fetchData()
  }

  const handleFilterInputChange = (e) => {
    const { value, name, type, checked } = e.target
    setFilter({ ...filter, [name]: type === 'checkbox' ? checked : value })
  }

  const handleDateRangeChange = (e) => {
    const value = e.target.value
    setDateRange(value)
    if (value) {
      setFilter({
        ...filter,
        start_date: moment().subtract(value, 'd').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
      })
    }
  }

  React.useEffect(() => {
    fetchData()
  }, [])

  return <>

    <h1>Page View Count</h1>
    <Box mb={3}>
      <form onSubmit={handleSubmitFilter}>
        <Grid container spacing={3}>
          <Grid item>
            <FormControl size="small" variant="outlined">
              <InputLabel shrink>Sub Admin</InputLabel>
              <SubAdminSelect
                size="small"
                fullWidth
                variant="outlined"
                name="sub_admin_id"
                value={filter.sub_admin_id || ''}
                onChange={handleFilterInputChange}
                autoWidth
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl size="small" variant="outlined">
              <InputLabel shrink>Date Range</InputLabel>
              <Select
                size="small"
                fullWidth
                variant="outlined"
                value={dateRange}
                onChange={handleDateRangeChange}
                autoWidth
              >
                <MenuItem value="0">Today</MenuItem>
                <MenuItem value="7">Last 7 Days</MenuItem>
                <MenuItem value="30">Last 30 Days</MenuItem>
                <MenuItem value="60">Last 60 Days</MenuItem>
                <MenuItem value="180">Last 180 Days</MenuItem>
                <MenuItem value="365">Last 365 Days</MenuItem>
                <MenuItem value="">Customs</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {!dateRange && <>
            <Grid item>
              <TextField
                size="small"
                variant="outlined"
                label="Start Date"
                type="date"
                name="start_date"
                value={filter.start_date || ''}
                onChange={handleFilterInputChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                size="small"
                variant="outlined"
                label="End Date"
                type="date"
                name="end_date"
                value={filter.end_date || ''}
                onChange={handleFilterInputChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </>}

          <Grid item>
            <Button variant="contained" color="primary" type="submit">Search</Button>
          </Grid>
        </Grid>
      </form>
    </Box>
    {loading && <LinearProgress />}
    {!loading && data.length > 0 && <Box>
      <XYPlot xType="ordinal" xDistance={200} height={600} width={1600} style={{ height: '700px' }} >
        <VerticalGridLines />
        <HorizontalGridLines />
        <XAxis tickLabelAngle={-90} />
        <YAxis />
        <VerticalBarSeries data={data} />
      </XYPlot>
    </Box>}

    {!loading && data.length === 0 && <Box>
      No Data Found.
    </Box>}

  </>
}

export default Home;
