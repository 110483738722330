import React from 'react';
import { observer } from 'mobx-react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Link } from 'react-router-dom';

import { get } from 'lodash';

export default observer(({ shippingMode, location }) => {
  return <TableRow key={shippingMode.id}>
    <TableCell component="th" scope="row">{shippingMode.id}</TableCell>
    <TableCell>{shippingMode.name}</TableCell>
    <TableCell>{shippingMode.max_cbm}</TableCell>
    <TableCell>{shippingMode.max_weight_lb}</TableCell>
    <TableCell>
      <IconButton color="primary" size="small" disabled={shippingMode.loading} component={Link} to={{
        pathname: `/shippingModes/${shippingMode.id}`,
        state: { background: location }
      }}>
        <EditIcon fontSize="inherit" />
      </IconButton>
      <IconButton color="secondary" size="small" disabled={shippingMode.loading} onClick={shippingMode.destroy}>
        <DeleteIcon fontSize="inherit" />
      </IconButton>
    </TableCell>
  </TableRow>
})
