import axios from 'axios';
import root from '../stores';
import qs from 'qs';


axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.timeout = 300000; // 300s
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_SERVER_API_URL;

// Format nested params correctly
axios.interceptors.request.use(config => {
  config.paramsSerializer = params => {
    // q is already included in the Axios package
    return qs.stringify(params, {
      arrayFormat: "brackets",
      encode: false
    });
  };

  const { token } = root.auth;

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

axios.interceptors.response.use(response => {
  const authorization = response.headers.authorization;

  const { token } = root.auth;

  if (authorization) {
    let result = authorization.match(/^Bearer (.+)/);
    if (result && result[1] !== token) {
      root.auth.setToken(result[1])
    }
  }

  return response;
}, error => {
  const response = error.response;
  if (response) {
    if (response.status === 401) {
      root.auth.loggedout();
    }
  }

  return Promise.reject(error);
});

export default axios;
