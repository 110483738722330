import { types, getParent, applySnapshot, flow } from 'mobx-state-tree';
import { CenterAdminDetail } from './models';

const CenterAdminStore = types
  .model('CenterAdminStore', {
    centerAdmin: types.optional(CenterAdminDetail, {}),
  })
  .volatile(self => ({
    loading: false,
    submitting: false,
  }))
  .views(self => ({
    get root() {
      return getParent(self);
    },
  }))
  .actions(self => ({
    setCenterAdmin(centerAdmin) {
      applySnapshot(self.centerAdmin, centerAdmin);
    },
    loadCenterAdmin: flow(function* (id) {
      try {
        self.loading = true;
        const response = yield self.root.api.centerAdmin.show(id);
        self.setCenterAdmin(response.data.data);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    submit: flow(function* (e, centerAdminId) {
      e && e.preventDefault && e.preventDefault();
      let response;
      try {
        self.submitting = true;
        if (centerAdminId != 0) {
          response = yield self.root.api.centerAdmin.update(centerAdminId, self.centerAdmin);
        }
        else {
          response = yield self.root.api.centerAdmin.store(self.centerAdmin);
        }
        self.setCenterAdmin(response.data.data);
      } catch (error) {
        self.root.ui.toast.error(error);
      }

      self.submitting = false;

      return response
    }),
  }))

export default CenterAdminStore;