import { types, getParent, applySnapshot, flow } from 'mobx-state-tree';
import { GeneralLedgerCodeDetail } from './models';

const GeneralLedgerCodeStore = types
  .model('GeneralLedgerCodeStore', {
    generalLedgerCode: types.optional(GeneralLedgerCodeDetail, {}),
  })
  .volatile(self => ({
    loading: false,
    submitting: false,
  }))
  .views(self => ({
    get root() {
      return getParent(self);
    },
  }))
  .actions(self => ({
    setGeneralLedgerCode(generalLedgerCode) {
      applySnapshot(self.generalLedgerCode, generalLedgerCode);
    },
    loadGeneralLedgerCode: flow(function* (id) {
      try {
        self.loading = true;
        self.setGeneralLedgerCode({});
        const response = yield self.root.api.generalLedgerCode.show(id);
        self.setGeneralLedgerCode(response.data.data);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    submit: flow(function* (e, generalLedgerCodeId) {
      e && e.preventDefault && e.preventDefault();

      let response;
      try {
        self.submitting = true;
        if (generalLedgerCodeId != 0) {
          response = yield self.root.api.generalLedgerCode.update(self.generalLedgerCode.id, self.generalLedgerCode);
        }
        else {
          response = yield self.root.api.generalLedgerCode.store(self.generalLedgerCode);
        }
        self.setGeneralLedgerCode(self.generalLedgerCode);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.submitting = false;

      return response;
    }),
  }))


export default GeneralLedgerCodeStore;
