export const hasValuesTrait = (values) => {
    return {
        setAttribute(name, value) {
            values[name] = value;
        },
        setAttributeByEvent(e) {
            const { value, checked, type, name } = e.target;
            if (type === 'checkbox') {
                values[name] = checked;
            } else if (type === 'number') {
                values[name] = Number(value);
            // } else if (type === 'datetime-local') {
            //     values[name] = dayjs(value).format('YYYY-MM-DD HH:mm:ss');
            } else {
                values[name] = value;
            }
        },
    }
}