import React from 'react';
import { Route, Switch, Redirect, useLocation } from "react-router-dom";


import Login from '../components/pages/Login';
import Home from '../components/pages/Home';
import CenterAdmins from '../components/pages/centerAdmin/CenterAdmins';
import CenterAdmin from '../components/pages/centerAdmin/CenterAdmin';
import CenterAdminModal from '../components/pages/centerAdmin/CenterAdmin/Modal';
import Admins from '../components/pages/admin/Admins';
import Admin from '../components/pages/admin/Admin';
import AdminModal from '../components/pages/admin/Admin/Modal';
import SubAdmins from '../components/pages/subAdmin/SubAdmins';
import SubAdmin from '../components/pages/subAdmin/SubAdmin';
import SubAdminModal from '../components/pages/subAdmin/SubAdmin/Modal';
import OceanCarriers from '../components/pages/oceanCarrier/OceanCarriers';
import OceanCarrier from '../components/pages/oceanCarrier/OceanCarrier';
import OceanCarrierModal from '../components/pages/oceanCarrier/OceanCarrier/Modal';
import Terminals from '../components/pages/terminal/Terminals';
import Terminal from '../components/pages/terminal/Terminal';
import TerminalModal from '../components/pages/terminal/Terminal/Modal';
import TradePartys from '../components/pages/tradeParty/TradePartys';
import TradeParty from '../components/pages/tradeParty/TradeParty';
import TradePartyModal from '../components/pages/tradeParty/TradeParty/Modal';
import ShippingModes from '../components/pages/shippingMode/ShippingModes';
import ShippingMode from '../components/pages/shippingMode/ShippingMode';
import ShippingModeModal from '../components/pages/shippingMode/ShippingMode/Modal';
import BillingCodes from '../components/pages/billingCode/BillingCodes';
import BillingCode from '../components/pages/billingCode/BillingCode';
import BillingCodeModal from '../components/pages/billingCode/BillingCode/Modal';
import GeneralLedgerCodes from '../components/pages/generalLedgerCode/GeneralLedgerCodes';
import GeneralLedgerCode from '../components/pages/generalLedgerCode/GeneralLedgerCode';
import GeneralLedgerCodeModal from '../components/pages/generalLedgerCode/GeneralLedgerCode/Modal';
import Locations from '../components/pages/location/Locations';
import Location from '../components/pages/location/Location';
import LocationModal from '../components/pages/location/Location/Modal';
import Phpinfo from '../components/pages/setting/Phpinfo';

const Routes = ({ isAuthenticated }) => {
  const location = useLocation();
  const background = location.state && location.state.background;

  return <>
    <Switch location={background || location}>
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/" component={Home} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/centerAdmins/:centerAdminId" component={CenterAdmin} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/centerAdmins/" component={CenterAdmins} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/admins/:adminId" component={Admin} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/admins" component={Admins} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/subAdmins/byAdmins/" component={SubAdmins} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/subAdmins/byAdmins/:adminId" component={SubAdmins} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/subAdmins/:subAdminId/:adminId" component={SubAdmin} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/oceanCarriers" component={OceanCarriers} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/oceanCarriers/:oceanCarrierId" component={OceanCarrier} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/terminals" component={Terminals} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/terminals/:terminalId" component={Terminal} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/tradeParties" component={TradePartys} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/tradeParties/:tradePartyId" component={TradeParty} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/shippingModes" component={ShippingModes} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/shippingModes/:shippingModeId" component={ShippingMode} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/billingCodes" component={BillingCodes} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/billingCodes/:billingCodeId" component={BillingCode} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/generalLedgerCodes" component={GeneralLedgerCodes} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/generalLedgerCodes/:generalLedgerCodeId" component={GeneralLedgerCode} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/locations" component={Locations} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/locations/:locationId" component={Location} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/setting/phpinfo" component={Phpinfo} />

      <Route exact path="/login" component={Login} />

      <Route render={() => 'Not Found'} />
    </Switch>

    {background && <Switch>
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/centerAdmins/:centerAdminId" component={CenterAdminModal} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/subAdmins/:subAdminId/" component={SubAdminModal} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/subAdmins/:subAdminId/:adminId" component={SubAdminModal} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/admins/:adminId" component={AdminModal} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/oceanCarriers/:oceanCarrierId" component={OceanCarrierModal} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/terminals/:terminalId" component={TerminalModal} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/tradeParties/:tradePartyId" component={TradePartyModal} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/shippingModes/:shippingModeId" component={ShippingModeModal} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/billingCodes/:billingCodeId" component={BillingCodeModal} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/generalLedgerCodes/:generalLedgerCodeId" component={GeneralLedgerCodeModal} />
      <PrivateRoute isAuthenticated={isAuthenticated} exact path="/locations/:locationId" component={LocationModal} />
    </Switch>}
  </>
}


const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Component  {...props} />
        ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location }
              }}
            />
          )
      }
    />
  );
}

export default Routes;
