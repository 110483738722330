import React from 'react';
import { observer } from 'mobx-react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import TableHeader from './TableHeader';
import TableBodyRow from './TableBodyRow';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';
import { useLocation } from 'react-router-dom';


const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default observer(({ loading, centerAdmins }) => {
  const classes = useStyles();

  const location = useLocation();

  return <>
    <Box width={1}>
      <Paper>
        <Box visibility={loading ? 'visible' : 'hidden'}><LinearProgress /></Box>

        <Table stickyHeader className={classes.table} size="small" >
          <TableHeader />
          <TableBody>
            {centerAdmins.map((centerAdmin) => (
              <TableBodyRow key={centerAdmin.id} centerAdmin={centerAdmin} location={location} />
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Box>
  </>;
})