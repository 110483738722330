import { types, getParent } from 'mobx-state-tree';
import { hasValuesTrait } from '../helper';

export const GeneralLedgerCodeRow = types
  .model({
    id: types.number,
    admin_id: 0,
    code: types.maybeNull(types.string),
    type: types.maybeNull(types.number),
    type_name: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    full_name: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
  })
  .actions(self => ({
    destroy() {
      getParent(self, 2).destroy(self.id);
    },
    ...hasValuesTrait(self),
  }))

export const GeneralLedgerCodeDetail = types
  .model({
    id: types.maybeNull(types.number),
    admin_id: 0,
    code: types.maybeNull(types.string),
    type: types.maybeNull(types.number),
    type_name: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    description: types.maybeNull(types.string),
  })
  .actions(self => ({
    ...hasValuesTrait(self),
  }))
