import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Form from './Form';

export default inject('root')(
  observer(({ root, shippingModeId }) => {
    const { shippingMode, loadShippingMode, submit, loading, submitting } = root.shippingMode;
    
    shippingModeId = shippingModeId || useParams().shippingModeId;

    useEffect(() => {
      if (shippingModeId != 0) {
        loadShippingMode(shippingModeId);
      }
    }, []);

    return <>
      <Form
        shippingModeId={shippingModeId}
        values={shippingMode}
        submit={submit}
        loading={loading}
        submitting={submitting}
        shippingModes={root.shippingModes}
      />
    </>
  })
);
