import { types, getEnv } from 'mobx-state-tree';
import AuthStore from './AuthStore';
import UIStore from './UIStore';
import AppStore from './AppStore';
import CenterAdminsStore from './centerAdmin/CenterAdminsStore';
import CenterAdminStore from './centerAdmin/CenterAdminStore';
import AdminsStore from './admin/AdminsStore';
import AdminStore from './admin/AdminStore';
import SubAdminsStore from './subAdmin/SubAdminsStore';
import SubAdminStore from './subAdmin/SubAdminStore';
import OceanCarriersStore from './oceanCarrier/OceanCarriersStore';
import OceanCarrierStore from './oceanCarrier/OceanCarrierStore';
import TerminalsStore from './terminal/TerminalsStore';
import TerminalStore from './terminal/TerminalStore';
import TradePartysStore from './tradeParty/TradePartysStore';
import TradePartyStore from './tradeParty/TradePartyStore';
import ShippingModesStore from './shippingMode/ShippingModesStore';
import ShippingModeStore from './shippingMode/ShippingModeStore';
import BillingCodesStore from './billingCode/BillingCodesStore';
import BillingCodeStore from './billingCode/BillingCodeStore';
import GeneralLedgerCodesStore from './generalLedgerCode/GeneralLedgerCodesStore';
import GeneralLedgerCodeStore from './generalLedgerCode/GeneralLedgerCodeStore';
import LocationsStore from './location/LocationsStore';
import LocationStore from './location/LocationStore';
import SettingsStore from './setting/SettingStore';

const RootStore = types
  .model('RootStore', {
    auth: types.optional(AuthStore, {}),
    ui: types.optional(UIStore, {}),
    app: types.optional(AppStore, {}),
    centerAdmins: types.optional(CenterAdminsStore, {}),
    centerAdmin: types.optional(CenterAdminStore, {}),
    admins: types.optional(AdminsStore, {}),
    admin: types.optional(AdminStore, {}),
    subAdmins: types.optional(SubAdminsStore, {}),
    subAdmin: types.optional(SubAdminStore, {}),
    oceanCarriers: types.optional(OceanCarriersStore, {}),
    oceanCarrier: types.optional(OceanCarrierStore, {}),
    terminals: types.optional(TerminalsStore, {}),
    terminal: types.optional(TerminalStore, {}),
    tradeParties: types.optional(TradePartysStore, {}),
    tradeParty: types.optional(TradePartyStore, {}),
    shippingModes: types.optional(ShippingModesStore, {}),
    shippingMode: types.optional(ShippingModeStore, {}),
    billingCodes: types.optional(BillingCodesStore, {}),
    billingCode: types.optional(BillingCodeStore, {}),
    generalLedgerCodes: types.optional(GeneralLedgerCodesStore, {}),
    generalLedgerCode: types.optional(GeneralLedgerCodeStore, {}),
    locations: types.optional(LocationsStore, {}),
    location: types.optional(LocationStore, {}),
    setting: types.optional(SettingsStore, {}),
  })
  .views(self => ({
    get api() {
      return getEnv(self).api;
    },
  }))
  .actions(self => ({
    start() {
      self.auth.loadUser().then(() => self.app.setApploaded(true));
    },
  }))

export default RootStore;
