import React from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

export default () => {
  return <TableHead>
    <TableRow>
      <TableCell>ID</TableCell>
      <TableCell>CODE</TableCell>
      <TableCell>TYPE</TableCell>
      <TableCell>NAME</TableCell>
      <TableCell>DESCRIPTION</TableCell>
      <TableCell></TableCell>
    </TableRow>
  </TableHead>
}
