import React from 'react';
import { observer } from 'mobx-react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Link } from 'react-router-dom';

import { get } from 'lodash';

export default observer(({ oceanCarrier, location }) => {
  return <TableRow key={oceanCarrier.id}>
    <TableCell component="th" scope="row">{oceanCarrier.id}</TableCell>
    <TableCell>{oceanCarrier.code}</TableCell>
    <TableCell>{oceanCarrier.name}</TableCell>
    <TableCell>{oceanCarrier.billing}</TableCell>
    <TableCell>{oceanCarrier.phone}</TableCell>
    <TableCell>{oceanCarrier.contact}</TableCell>
    <TableCell>{oceanCarrier.email}</TableCell>
    <TableCell>{oceanCarrier.admin_id != 0 ? get(oceanCarrier, 'admin.name') :  <Chip color="primary" label="Public" />}</TableCell>
    <TableCell>
      <IconButton color="primary" size="small" disabled={oceanCarrier.loading} component={Link} to={{
        pathname: `/oceanCarriers/${oceanCarrier.id}`,
        state: { background: location }
      }}>
        <EditIcon fontSize="inherit" />
      </IconButton>
      <IconButton color="secondary" size="small" disabled={oceanCarrier.loading} onClick={oceanCarrier.destroy}>
        <DeleteIcon fontSize="inherit" />
      </IconButton>
    </TableCell>
  </TableRow>
})
