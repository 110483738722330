import { types, getParent, applySnapshot, flow } from 'mobx-state-tree';
import { TradePartyDetail } from './models';

const TradePartyStore = types
  .model('TradePartyStore', {
    tradeParty: types.optional(TradePartyDetail, {}),
  })
  .volatile(self => ({
    loading: false,
    submitting: false,
  }))
  .views(self => ({
    get root() {
      return getParent(self);
    },
  }))
  .actions(self => ({
    setTradeParty(tradeParty) {
      applySnapshot(self.tradeParty, tradeParty);
    },
    loadTradeParty: flow(function* (id) {
      try {
        self.loading = true;
        self.setTradeParty({});
        const response = yield self.root.api.tradeParty.show(id);
        self.setTradeParty(response.data.data);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    addContact: () => {
      self.tradeParty.contacts.push({
        cell: '',
        centerdb_id: 0,
        contactable_id: self.tradeParty.id,
        contactable_type: "App\\Models\\TradeParty",
        email: '',
        emailable: false,
        fax: '',
        id: null,
        main_contact: false,
        name: '',
        phone: '',
        remark: '',
        type: '',
      });
    },
    submit: flow(function* (e, tradePartyId, isPublic) {
      e && e.preventDefault && e.preventDefault();

      let response;
      try {
        self.submitting = true;
        if (tradePartyId != 0) {
          self.tradeParty.isPublic = isPublic;
          response = yield self.root.api.tradeParty.update(self.tradeParty.id, self.tradeParty);
        }
        else {
          response = yield self.root.api.tradeParty.store(self.tradeParty);
        }
        self.setTradeParty(self.tradeParty);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.submitting = false;

      return response;
    }),
  }))


export default TradePartyStore;
