import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Form from './Form';

export default inject('root')(
  observer(({ root, centerAdminId }) => {
    const { centerAdmin, loadCenterAdmin, submit, loading, submitting } = root.centerAdmin;
    centerAdminId = centerAdminId || useParams().centerAdminId;
    useEffect(() => {
      if (centerAdminId != 0) {
        loadCenterAdmin(centerAdminId);
      }
    }, []);

    return <>
      <Form
        centerAdminId={centerAdminId}
        values={centerAdmin}
        submit={submit}
        loading={loading}
        submitting={submitting}
      />
    </>
  })
);
