import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Form from './Form';

export default inject('root')(
  observer(({ root, billingCodeId }) => {
    const { billingCode, loadBillingCode, submit, loading, submitting } = root.billingCode;
    
    billingCodeId = billingCodeId || useParams().billingCodeId;

    useEffect(() => {
      if (billingCodeId != 0) {
        loadBillingCode(billingCodeId);
      }
    }, []);

    return <>
      <Form
        billingCodeId={billingCodeId}
        values={billingCode}
        submit={submit}
        loading={loading}
        submitting={submitting}
        billingCodes={root.billingCodes}
      />
    </>
  })
);
