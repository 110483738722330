import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Form from './Form';

export default inject('root')(
  observer(({ root, subAdminId }) => {
    const { subAdmin, loadSubAdmin, submit, loading, submitting } = root.subAdmin;
    subAdminId = subAdminId || useParams().subAdminId;

    useEffect(() => {
      if (subAdminId != 0) {
        loadSubAdmin(subAdminId);
      }
    }, []);

    return <>
      <Form
        subAdminId={subAdminId}
        values={subAdmin}
        submit={submit}
        loading={loading}
        submitting={submitting}
        subAdmins={root.subAdmins}
      />
    </>
  })
);
