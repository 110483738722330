import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';

export default inject('root')(
  observer(({ root }) => {
    const { loadPhpinfo, phpinfo } = root.setting;

    useEffect(() => {
      loadPhpinfo();
    }, []);

    return (
      <>
        <div dangerouslySetInnerHTML={{ __html: phpinfo }} />
      </>
    );
  })
)
