import request from './request';

export const location = {
  index(params = {}) {
    return request.get(`locations`, { params });
  },
  show(id) {
    return request.get(`locations/${id}`);
  },
  store(values) {
    return request.post(`locations`, values);
  },
  update(id, values) {
    return request.put(`locations/${id}`, values);
  },
  destroy(id) {
    return request.delete(`locations/${id}`);
  },
}
