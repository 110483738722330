export const GENERAL_LEDGER_CODE_TYPE_ASSET = 1;
export const GENERAL_LEDGER_CODE_TYPE_LIABILITY = 2;
export const GENERAL_LEDGER_CODE_TYPE_EQUITY = 3;
export const GENERAL_LEDGER_CODE_TYPE_INCOME = 4;
export const GENERAL_LEDGER_CODE_TYPE_COST = 5;
export const GENERAL_LEDGER_CODE_TYPE_EXPENSE = 6;
export const GENERAL_LEDGER_CODE_TYPE_OTHER_INCOME = 7;
export const GENERAL_LEDGER_CODE_TYPE_OTHER_EXPENSE = 8;
export const GENERAL_LEDGER_CODE_TYPE_INCOME_TAX = 9;

export const GENERAL_LEDGER_CODE_TYPES_MAP = {
    'ASSET': GENERAL_LEDGER_CODE_TYPE_ASSET,
    'LIABILITY': GENERAL_LEDGER_CODE_TYPE_LIABILITY,
    'EQUITY': GENERAL_LEDGER_CODE_TYPE_EQUITY,
    'INCOME': GENERAL_LEDGER_CODE_TYPE_INCOME,
    'COST': GENERAL_LEDGER_CODE_TYPE_COST,
    'EXPENSE': GENERAL_LEDGER_CODE_TYPE_EXPENSE,
    'OTHER_INCOME': GENERAL_LEDGER_CODE_TYPE_OTHER_INCOME,
    'OTHER_EXPENSE': GENERAL_LEDGER_CODE_TYPE_OTHER_EXPENSE,
    'INCOME_TAX': GENERAL_LEDGER_CODE_TYPE_INCOME_TAX,
}