import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const Selector = ({menuItems, ...props}) => {

  return (
    <>
      <Select {...props} >
        {menuItems.map((menuItem) =>
          <MenuItem value={menuItem}>{menuItem}</MenuItem>
        )}
      </Select>
    </>
  );
}

export default Selector;