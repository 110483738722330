export const TRADE_PARTY_TYPE_US_FORWRDER = 'US Forwarder';
export const TRADE_PARTY_TYPE_OVERSEA_PARTNER = 'Overseas Partner';
export const TRADE_PARTY_TYPE_US_COLOADER = 'US Co-Loader';
export const TRADE_PARTY_TYPE_OTHERS = 'Others';
export const TRADE_PARTY_TYPE_US_CUSTOMS_BROKER = 'US Customs Broker';
export const TRADE_PARTY_TYPE_SHIPPER = 'Shipper';
export const TRADE_PARTY_TYPE_US_IMPORTER = 'US Importer';
export const TRADE_PARTY_TYPE_US_OTR_CARRIER = 'US OTR Carrier';
export const TRADE_PARTY_TYPE_US_DRAYAGE_TRUCKER = 'US Drayage Trucker';
export const TRADE_PARTY_TYPE_US_WAREHOUSE = 'US Warehouse';
export const TRADE_PARTY_TYPE_FULFILLMENT_CENTER = 'Fulfillment Center';


export const TRADE_PARTY_TYPES = [
  TRADE_PARTY_TYPE_OVERSEA_PARTNER,
  TRADE_PARTY_TYPE_SHIPPER,
  TRADE_PARTY_TYPE_US_FORWRDER,
  TRADE_PARTY_TYPE_US_COLOADER,
  TRADE_PARTY_TYPE_US_CUSTOMS_BROKER,
  TRADE_PARTY_TYPE_US_IMPORTER,
  TRADE_PARTY_TYPE_US_DRAYAGE_TRUCKER,
  TRADE_PARTY_TYPE_US_WAREHOUSE,
  TRADE_PARTY_TYPE_US_OTR_CARRIER,
  TRADE_PARTY_TYPE_FULFILLMENT_CENTER,
  TRADE_PARTY_TYPE_OTHERS,
];

export const COUNTRIES = [
  "AFGHANISTAN",
  "ALBANIA",
  "ALGERIA",
  "AMERICAN SAMOA",
  "ANDORRA",
  "ANGOLA",
  "ANGUILLA",
  "ANTARCTICA",
  "ANTIGUA AND BARBUDA",
  "ARGENTINA",
  "ARMENIA",
  "ARUBA",
  "AUSTRALIA",
  "AUSTRIA",
  "AZERBAIJAN",
  "BAHAMAS",
  "BAHRAIN",
  "BANGLADESH",
  "BARBADOS",
  "BELGIUM",
  "BELIZE",
  "BENIN",
  "BERMUDA",
  "BHUTAN",
  "BOLIVIA",
  "BOSNIA-HERCEGOVINA",
  "BOTSWANA",
  "BRAZIL",
  "BRITISH INDIAN OCEAN TERRITORY",
  "BRITISH VIRGIN ISLANDS",
  "BRUNEI DARUSSALAM",
  "BULGARIA",
  "BURKINA",
  "BURMA (MYANMAR)",
  "BURUNDI",
  "BYELARUS",
  "CAMBODIA",
  "CAMEROON",
  "CANADA",
  "CAPE VERDE",
  "CAYMAN ISLAND",
  "CENTRAL AFRICAN REPUBLIC",
  "CHAD",
  "CHILE",
  "CHINA",
  "CHRISTMAS ISLANDS",
  "COCOS (KEELING) ISLAND",
  "COLOMBIA",
  "COMOROS",
  "CONGO (BRAZZAVILLE)",
  "COOK ISLANDS",
  "COSTA RICA",
  "COTE D'IVOIRE",
  "CROATIA",
  "CUBA",
  "CURACAO",
  "CYPRUS",
  "CZECH REPUBLIC, THE",
  "DEMOCRATIC REPUBLIC OF CONGO (KINSHASA)",
  "DENMARK",
  "DJIBOUTI",
  "DOMINICA",
  "DOMINICAN REPUBLIC",
  "ECUADOR",
  "EGYPT",
  "EL SALVADOR",
  "EQUATORIAL GUINEA",
  "ERITREA",
  "ESTONIA",
  "ETHIOPIA",
  "FALKLAND ISLANDS (MALVINAS)",
  "FAROE ISLANDS",
  "FIJI",
  "FINLAND",
  "FRANCE",
  "FRENCH GUIANA",
  "FRENCH POLYNESIA",
  "FRENCH SOUTHERN AND ANTARTIC LANDS",
  "GABON",
  "GAMBIA, THE",
  "GAZA STRIP",
  "GAZA STRIP (PALESTINIAN)",
  "GEORGIA",
  "GERMANY, FEDERAL REPUBLIC OF",
  "GHANA",
  "GIBRALTAR",
  "GREECE",
  "GREENLAND",
  "GRENADA",
  "GUADELOUPE",
  "GUAM",
  "GUATEMALA",
  "GUINEA",
  "GUINEA-BISSAU",
  "GUYANA",
  "HAITI",
  "HEARD ISLAND AND MCDONALD ISLANDS",
  "HONDURAS",
  "HONG KONG",
  "HUNGARY",
  "ICELAND",
  "INDIA",
  "INDONESIA",
  "IRAN",
  "IRAQ",
  "IRELAND",
  "ISLE OF MAN",
  "ISRAEL",
  "ITALY",
  "JAMAICA",
  "JAPAN",
  "JERSEY",
  "JORDAN",
  "KAZAKHSTAN",
  "KENYA",
  "KIRIBATI",
  "KOREA, DEMOCRATIC PEOPLES REPUBLIC OF",
  "KOREA, REPUBLIC OF",
  "KOSOVO",
  "KUWAIT",
  "KYRGYZSTAN",
  "LAOS (LAO PEOPLE'S DEM. REP.)",
  "LATVIA",
  "LEBANON",
  "LESOTHO",
  "LIBERIA",
  "LIBYA",
  "LIECHTENSTEIN",
  "LITHUANIA",
  "LUXEMBOURG",
  "MACAU",
  "MACEDONIA (SKOPJE)",
  "MADAGASCAR",
  "MALAWI",
  "MALAYSIA",
  "MALDIVES",
  "MALI",
  "MALTA",
  "MARSHALL ISLANDS",
  "MARTINIQUE",
  "MAURITANIA",
  "MAURITIUS",
  "MAYOTTE",
  "MEXICO",
  "MICRONESIA, FEDERAL STATES OF",
  "MOLDOVA",
  "MONACO",
  "MONGOLIA",
  "MONTENEGRO",
  "MONTSERAT",
  "MOROCCO",
  "MOZAMBIQUE",
  "MYANMAR",
  "NAMIBIA",
  "NAURU",
  "NEPAL",
  "NETHERLANDS",
  "NETHERLANDS ANTILLES",
  "NEW CALEDONIA",
  "NEW ZEALAND",
  "NICARAGUA",
  "NIGER",
  "NIGERIA",
  "NIUE",
  "NORFOLK ISLAND",
  "NORTHERN MARIANA ISLAND",
  "NORWAY",
  "OMAN",
  "PAKISTAN",
  "PALAU",
  "PANAMA",
  "PAPUA NEW GUINEA",
  "PARAGUAY",
  "PERU",
  "PHILIPPINES",
  "PITCAIRN ISLAND",
  "POLAND",
  "PORTUGAL",
  "PUERTO RICO",
  "QATAR",
  "REUNION",
  "ROMANIA",
  "RUSSIA",
  "RWANDA",
  "SAINT LUCIA",
  "SAINT MARTIN",
  "SAMOA",
  "SAN MARINO",
  "SAO TOME AND PRINCIPE",
  "SAUDI ARABIA",
  "SENEGAL",
  "SERBIA",
  "SERBIA MONTENEGRO",
  "SEYCHELLES",
  "SIERRA LEONE",
  "SINGAPORE",
  "SINT MAARTEN",
  "SLOVAKIA",
  "SLOVENIA",
  "SOLOMON ISLANDS",
  "SOMALIA",
  "SOUTH AFRICA",
  "SOUTH SUDAN",
  "SPAIN",
  "SRI LANKA",
  "ST. BARTHELEMY",
  "ST. HELENA",
  "ST. KITTS AND NEVIS",
  "ST. PIERRE AND MIQUELON",
  "SUDAN",
  "SURINAME",
  "SVALVARD AND JAN MAYEN ISLANDS",
  "SWAZILAND",
  "SWEDEN",
  "SWITZERLAND",
  "SYRIAN ARAB REPUBLIC",
  "TAIWAN",
  "TAJIKISTAN",
  "TANZANIA, UNITED REPUBLIC OF",
  "THAILAND",
  "TIMOR-LESTE",
  "TOGO",
  "TOKELAU",
  "TONGA",
  "TRINIDAD AND TOBAGO",
  "TUNISIA",
  "TURKEY",
  "TURKMENISTAN",
  "TURKS AND CAICOS ISLAND",
  "TUVALU",
  "UGANDA",
  "UKRAINE",
  "UNITED ARAB EMIRATES",
  "UNITED KINGDOM",
  "UNITED STATES",
  "UNITED STATES MINOR OUTLYING ISLANDS",
  "URUGUAY",
  "UZBEKISTAN",
  "VANUATU",
  "VATICAN CITY",
  "VENEZUELA",
  "VIETNAM",
  "VIRGIN ISLANDS OF THE UNITED STATES",
  "WALLIS AND FUTUNA ISLANDS",
  "WEST BANK",
  "WESTERN SAHARA",
  "YEMEN, REPUBLIC OF",
  "YUGOSLAVIA",
  "ZAIRE",
  "ZAMBIA",
  "ZIMBABWE"
];

export const DISABLED = -1;
export const PENDING = 0;
export const ENABLED = 2;

export const ACCOUNT_STATUSES_MAP = {
  'DISABLED': DISABLED,
  'PENDING': PENDING,
  'ENABLED': ENABLED,
}
