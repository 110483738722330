import { types, getParent, applySnapshot, flow } from 'mobx-state-tree';
import { TerminalDetail } from './models';

const TerminalStore = types
  .model('TerminalStore', {
    terminal: types.optional(TerminalDetail, {}),
  })
  .volatile(self => ({
    loading: false,
    submitting: false,
  }))
  .views(self => ({
    get root() {
      return getParent(self);
    },
  }))
  .actions(self => ({
    setTerminal(terminal) {
      applySnapshot(self.terminal, terminal);
    },
    loadTerminal: flow(function* (id) {
      try {
        self.loading = true;
        self.setTerminal({});
        const response = yield self.root.api.terminal.show(id);
        self.setTerminal(response.data.data);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    submit: flow(function* (e, terminalId, isPublic) {
      e && e.preventDefault && e.preventDefault();

      let response;
      try {
        self.submitting = true;
        if (terminalId != 0) {
          self.terminal.isPublic = isPublic;
          response = yield self.root.api.terminal.update(self.terminal.id, self.terminal);
        }
        else {
          response = yield self.root.api.terminal.store(self.terminal);
        }
        self.setTerminal(self.terminal);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.submitting = false;

      return response;
    }),
  }))


export default TerminalStore;
