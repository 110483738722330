import { get } from 'lodash';
import { useSnackbarRef } from '../components/layouts/ToastProvider';

const extractError = (error) => {
  let text;

  if (!!(text = get(error, 'response.data.message'))) {
    return text;
  }

  if (!!(text = get(error, 'response.data.error'))) {
    return text;
  }

  if (!!(text = get(error, 'message'))) {
    return text;
  }

  return 'Something wrong';
}

const _toast = (msg, options = {}) => {
  useSnackbarRef.enqueueSnackbar(msg, options)
}

export const toast = {
  toast: _toast,
  error(error) {
    const e = function (msg) {
      _toast(msg, { variant: 'error' });
    }

    return e(extractError(error));
  },

  success(msg, options = {}) {
    _toast(msg, { ...options, variant: 'success' });
  },
  info(msg, options = {}) {
    _toast(msg, { ...options, variant: 'info' });
  },
  warning(msg, options = {}) {
    _toast(msg, { ...options, variant: 'warning' });
  },
}
