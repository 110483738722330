import { types, getParent, applySnapshot, flow } from 'mobx-state-tree';
import { ShippingModeDetail } from './models';

const ShippingModeStore = types
  .model('ShippingModeStore', {
    shippingMode: types.optional(ShippingModeDetail, {}),
  })
  .volatile(self => ({
    loading: false,
    submitting: false,
  }))
  .views(self => ({
    get root() {
      return getParent(self);
    },
  }))
  .actions(self => ({
    setShippingMode(shippingMode) {
      applySnapshot(self.shippingMode, shippingMode);
    },
    loadShippingMode: flow(function* (id) {
      try {
        self.loading = true;
        self.setShippingMode({});
        const response = yield self.root.api.shippingMode.show(id);
        self.setShippingMode(response.data.data);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    submit: flow(function* (e, shippingModeId) {
      e && e.preventDefault && e.preventDefault();

      let response;
      try {
        self.submitting = true;
        if (shippingModeId != 0) {
          response = yield self.root.api.shippingMode.update(self.shippingMode.id, self.shippingMode);
        }
        else {
          response = yield self.root.api.shippingMode.store(self.shippingMode);
        }
        self.setShippingMode(self.shippingMode);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.submitting = false;

      return response;
    }),
  }))


export default ShippingModeStore;
