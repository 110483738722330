import React from 'react';
import { inject } from 'mobx-react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import ScrollTop from './ScrollTop';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
  },
  navbar: {
    flexGrow: 1,
    '& > *': {
      margin: theme.spacing(1, 1.5),
    }
  },
  title: {
    margin: theme.spacing(1, 1.5),
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
}));


const list = [
  {
    title: 'Dashboard',
    to: '/',
  },
  {
    title: 'Center Admin',
    to: '/centerAdmins',
  },
  {
    title: 'Admin',
    to: '/admins',
  },
  {
    title: 'Entities',
    children: [
      {
        title: 'OceanCarrier',
        to: '/oceanCarriers',
      },
      {
        title: 'Terminal',
        to: '/terminals',
      },
      {
        title: 'TradeParty',
        to: '/tradeParties',
      },
      {
        title: 'ShippingModes',
        to: '/shippingModes',
      },
      {
        title: 'BillingCodes',
        to: '/billingCodes',
      },
      {
        title: 'GeneralLedgerCodes',
        to: '/generalLedgerCodes',
      },
      {
        title: 'Locations',
        to: '/locations',
      },
    ],
  },
];


export default inject(stores => ({
  logout: stores.root.auth.logout,
}))(({ logout }) => {
  const classes = useStyles();
  return <>
    <AppBar position="static">
      <Toolbar className={classes.toolbar} id="back-to-top-anchor">
        <Button size="large" to="/" color="inherit" component={Link} className={classes.title}>Center 3PL</Button>
        <Navbar list={list} />
        <Button size="small" variant="outlined" color="inherit" onClick={logout}> Logout</Button>
      </Toolbar>
    </AppBar>
    <ScrollTop />
  </>
});

const Navbar = ({ list }) => {
  const classes = useStyles();

  return <nav className={classes.navbar}>
    {list.map((item, i) => <NavbarItem key={i} {...item} />)}
  </nav>
}

const NavbarItem = ({ title, to, children }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (children) {
    return <>
      <Button color="inherit" onClick={handleClick}>{title}</Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {children.map((c, i) => <MenuItem key={i} onClick={handleClose}><NavbarItem  {...c} /></MenuItem>)}
      </Menu>
    </>
  }

  return <Button to={to} color="inherit" component={Link}>{title}</Button>
}

Navbar.propTypes = {
  list: PropTypes.array.isRequired,
}

NavbarItem.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string,
  children: PropTypes.array,
}
