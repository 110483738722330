import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Form from './Form';

export default inject('root')(
  observer(({ root, oceanCarrierId }) => {
    const { oceanCarrier, loadOceanCarrier, submit, loading, submitting } = root.oceanCarrier;
    oceanCarrierId = oceanCarrierId || useParams().oceanCarrierId;

    useEffect(() => {
      if (oceanCarrierId != 0) {
        loadOceanCarrier(oceanCarrierId);
      }
    }, []);

    return <>
      <Form
        oceanCarrierId={oceanCarrierId}
        values={oceanCarrier}
        submit={submit}
        loading={loading}
        submitting={submitting}
        oceanCarriers={root.oceanCarriers}
      />
    </>
  })
);
