import React from 'react';
import { observer } from 'mobx-react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Link } from 'react-router-dom';

export default observer(({ generalLedgerCode, location }) => {
  return <TableRow key={generalLedgerCode.id}>
    <TableCell component="th" scope="row">{generalLedgerCode.id}</TableCell>
    <TableCell>{generalLedgerCode.code}</TableCell>
    <TableCell>{generalLedgerCode.type_name}</TableCell>
    <TableCell>{generalLedgerCode.name}</TableCell>
    <TableCell>{generalLedgerCode.description}</TableCell>
    <TableCell>
      <IconButton color="primary" size="small" disabled={generalLedgerCode.loading} component={Link} to={{
        pathname: `/generalLedgerCodes/${generalLedgerCode.id}`,
        state: { background: location }
      }}>
        <EditIcon fontSize="inherit" />
      </IconButton>
      <IconButton color="secondary" size="small" disabled={generalLedgerCode.loading} onClick={generalLedgerCode.destroy}>
        <DeleteIcon fontSize="inherit" />
      </IconButton>
    </TableCell>
  </TableRow>
})
