import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

export default inject('root')(
  observer(({ root, ...props }) => {
    const { generalLedgerCodes, loadGeneralLedgerCodes, } = root.generalLedgerCodes;

    useEffect(() => {
      loadGeneralLedgerCodes();
    }, []);

    return (
      <>
        <Select {...props} >
          <MenuItem value={0}>&nbsp;</MenuItem>
          {generalLedgerCodes.map((generalLedgerCode) =>
            <MenuItem value={generalLedgerCode.id}>{generalLedgerCode.name}</MenuItem>
          )}
        </Select>
      </>
    );
  })
)