import { types, getParent, applySnapshot, flow } from 'mobx-state-tree';
import { AdminDetail } from './models';

const AdminStore = types
  .model('AdminStore', {
    admin: types.optional(AdminDetail, {}),
  })
  .volatile(self => ({
    loading: false,
    submitting: false,
  }))
  .views(self => ({
    get root() {
      return getParent(self);
    },
  }))
  .actions(self => ({
    setAdmin(admin) {
      applySnapshot(self.admin, admin);
    },
    loadAdmin: flow(function* (id) {
      try {
        self.loading = true;
        const response = yield self.root.api.admin.show(id);
        self.setAdmin(response.data.data);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    regenerateToken: flow(function* (id) {
      try {
        self.loading = true;
        const response = yield self.root.api.admin.regenerateToken(id);
        self.setAdmin({ ...self.admin, api_token: response.data.apiToken });
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    submit: flow(function* (e, adminId) {
      e && e.preventDefault && e.preventDefault();

      let response;
      try {
        self.submitting = true;
        if (adminId != 0) {
          response = yield self.root.api.admin.update(self.admin.id, self.admin);
        }
        else {
          response = yield self.root.api.admin.store(self.admin);
        }
        self.setAdmin(response.data.data);
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.submitting = false;

      return response;
    }),
  }))


export default AdminStore;
