import React from 'react';
import { PropTypes, observer } from 'mobx-react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  buttonGroup: {
    '& > *': {
      marginLeft: theme.spacing(1),
    },
  },
}));

const Form = observer(({ centerAdminId, values, submit, loading, submitting }) => {
  const classes = useStyles();

  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    submit(e, centerAdminId).then((done) => done && history.goBack());
  }

  return <>
    <Box visibility={loading ? 'visible' : 'hidden'}><LinearProgress /></Box>
    <form noValidate onSubmit={handleSubmit}>
      <TextField
        label="NAME"
        name="name"
        margin="normal"
        value={values.name || ''}
        onChange={values.setAttributeByEvent}
        fullWidth
        required
      />
      <TextField
        label="USERNAME"
        name="username"
        margin="normal"
        value={values.username || ''}
        onChange={values.setAttributeByEvent}
        fullWidth
        required
      />
      <TextField
        label="EMAIL"
        name="email"
        margin="normal"
        value={values.email || ''}
        onChange={values.setAttributeByEvent}
        fullWidth
        required
      />
       <TextField
        label="PASSWORD"
        name="password"
        margin="normal"
        value={values.password || ''}
        onChange={values.setAttributeByEvent}
        fullWidth
      />
      <Box mt={2} display="flex" flexDirection="row-reverse" className={classes.buttonGroup}>
        <Button
          variant="contained"
          disabled={submitting}
          onClick={history.goBack}
        >
          Cancel
      </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={submitting}
        >
          Save
      </Button>
      </Box>
    </form>
  </>
})

export default Form;

Form.propTypes = {
  form: PropTypes.objectOrObservableObject.isRequired,
}
