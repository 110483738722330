import { types, getParent, flow } from 'mobx-state-tree';
import { AdminRow } from './models';

const AdminsStore = types
  .model('AdminsStore', {
    admins: types.array(AdminRow),
  })
  .volatile(self => ({
    loading: false,
  }))
  .views(self => ({
    get root() {
      return getParent(self);
    },
  }))
  .actions(self => ({
    loadAdmins: flow(function* () {
      try {
        self.loading = true;
        const response = yield self.root.api.admin.index();
        self.admins = response.data.data;
      } catch (error) {
        self.root.ui.toast.error(error);
      }
      self.loading = false;
    }),
    delete: flow(function* (id) {
      if (window.confirm('Are you sure to delete it?')) {
        try {
          yield self.root.api.admin.destroy(id);
        } catch (error) {
          self.root.ui.toast.error(error);
        }
      }
    }),
    importEntitiesDefaultData: flow(function* (id) {
      try {
        yield self.root.api.admin.importEntitiesDefaultData(id);
        self.root.api.toast.success('Imported');
      } catch (error) {
        self.root.ui.toast.error(error);
      }
    }),
  }))


export default AdminsStore;
