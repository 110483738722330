import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Header from './Header';
import Table from './Table';
import Pagination from './Pagination';

export default inject('root')(
  observer(({ root }) => {
    const { locations, loadLocations, loading, pagination } = root.locations;

    useEffect(() => {
      loadLocations();
    }, []);

    return (
      <>
        <Header />
        <Table
          loading={loading}
          locations={locations}
        />
         <Pagination pagination={pagination} />
      </>
    );
  })
)
