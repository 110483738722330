import React from 'react';
import { observer } from 'mobx-react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Link } from 'react-router-dom';

import { get } from 'lodash';

export default observer(({ tradeParty, location }) => {
  return <TableRow key={tradeParty.id}>
    <TableCell component="th" scope="row">{tradeParty.id}</TableCell>
    <TableCell>{tradeParty.code}</TableCell>
    <TableCell>{tradeParty.name}</TableCell>
    <TableCell>{tradeParty.type}</TableCell>
    <TableCell>{tradeParty.country}</TableCell>
    <TableCell>{tradeParty.admin_id != 0 ? get(tradeParty, 'admin.name') :  <Chip color="primary" label="Public" />}</TableCell>
    <TableCell>
      <IconButton color="primary" size="small" disabled={tradeParty.loading} component={Link} to={{
        pathname: `/tradeParties/${tradeParty.id}`,
        state: { background: location }
      }}>
        <EditIcon fontSize="inherit" />
      </IconButton>
      <IconButton color="secondary" size="small" disabled={tradeParty.loading} onClick={tradeParty.destroy}>
        <DeleteIcon fontSize="inherit" />
      </IconButton>
    </TableCell>
  </TableRow>
})
