import React from 'react';
import { observer } from 'mobx-react';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import { Link, Link as RouteLink } from 'react-router-dom';
import Chip from '@material-ui/core/Chip';

export default observer(({ admin, location }) => {
  return <TableRow key={admin.id}>
    <TableCell component="th" scope="row">{admin.id}</TableCell>
    <TableCell>{admin.name}</TableCell>
    <TableCell>{admin.username}</TableCell>
    <TableCell>{admin.email}</TableCell>
    <TableCell>
      <div>
        {admin.api_v2_enabled ?
          <Chip size="small" label="Enabled" color="primary" /> :
          <Chip
            size="small"
            label="Disabled"
            color="secondary"
          />}
      </div>
    </TableCell>
    <TableCell>
      <Link to={`subAdmins/byAdmins/${admin.id}`}>
        <Button variant="contained" color="primary" type="button">Sub Admins</Button>
      </Link>
    </TableCell>
    <TableCell>
      <Button onClick={admin.importEntitiesDefaultData}>IMPORT</Button>
    </TableCell>
    <TableCell>
      <IconButton color="primary" size="small" disabled={admin.loading} component={RouteLink} to={{
        pathname: `/admins/${admin.id}`,
        state: { background: location }
      }}>
        <EditIcon fontSize="inherit" />
      </IconButton>
      <IconButton color="secondary" size="small" disabled={admin.loading} onClick={admin.delete}>
        <DeleteIcon fontSize="inherit" />
      </IconButton>
    </TableCell>

  </TableRow>
})
