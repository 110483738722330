import request from './request';

export const centerAdmin = {
  index(params = {}) {
    return request.get('centerAdmins', { params });
  },
  show(id) {
    return request.get(`centerAdmins/${id}`);
  },
  store(values) {
    return request.post(`centerAdmins`, values)
  },
  update(id, values) {
    return request.put(`centerAdmins/${id}`, values);
  },
  destroy(id) {
    return request.delete(`centerAdmins/${id}`)
  },
}