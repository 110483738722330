import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import ScrollTop from './ScrollTop';
import { observer } from 'mobx-react';
import Navbar from './Navbar';

const useStyles = makeStyles((theme) => ({
  root: {
  },
  content: {
    padding: theme.spacing(3),
  },
}));

const Layout = observer(({ children, isAuthenticated }) => {
  const classes = useStyles();


  return isAuthenticated ? <>
    <div className={classes.root} >
      <Navbar />
      <main className={classes.content}>
        <Box>
          {children}
        </Box>
      </main>
      <ScrollTop />
    </div >
  </> : children;
})



export default Layout;
