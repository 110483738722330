import React, { useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import Header from './Header';
import Table from './Table';

export default inject('root')(
    observer(({ root }) => {
        const { admins, loadAdmins, loading } = root.admins;

        useEffect(() => {
            loadAdmins();
        }, []);

        return (
            <>
                <Header />
                <Table
                    loading={loading}
                    admins={admins}
                />
            </>
        );
    })
)